export default {
  "ABOUT": "εισαγωγή",
  "TOKENOMICS": "Tokenomics",
  "STAKING": "ποντάρισμα",
  "HOW TO BUY": "Πώς να αγοράσετε",
  "ROADMAP": "οδικός χάρτης",
  "FAQ": "Συχνές Ερωτήσεις",
  "BUY NOW": "αγοράστε τώρα",
  "ELONXCAT is the real": "Το ELONXCAT είναι πραγματικό",
  "ELONMUSK": "Έλον Μασκ",
  "SpaceX and X": "SpaceX και",
  "meme project": "έργο meme",
  "Advanced Web3 Ecosystem": "Προηγμένο Οικοσύστημα Web3",
  "Potential of project": "Δυνατότητα έργου",
  "Market cap Target": "κεφαλαιοποίηση της αγοράς-στόχου",
  "Investors": "επενδυτές",
  "WHITE PAPER": "λευκό βιβλίο",
  "AUDIT": "Ευχαριστώ",
  "Presale will start soon. Join the Presale": "Η προπώληση ξεκινά σύντομα. Λάβετε μέρος στην προπώληση",
  "TOKEN DISTRIBUTION DATE": "Ημερομηνία διανομής διακριτικού",
  "Raised": "Το ποσό που συγκεντρώθηκε",
  "ELONXCAT sold": "Όγκος πωλήσεων ELONXCAT",
  "BUY": "αγορά",
  "MULTI BRIDGE UTILITY": "Βοηθητικό πρόγραμμα πολλαπλών γεφυρών",
  "Total Supply": "συνολική προσφορά",
  "ELONXCAT ACHIEVEMENTS": "ELONXCAT Performance",
  "Ecosystem Percentage": "αναλογία οικοσυστήματος",
  "ELONXCAT QUEST GAME": "Παιχνίδι ELONXCAT Quest",
  "ELONXCAT GAME PORTAL SOON": "Σύντομα η πύλη παιχνιδιών ELONXCAT",
  "NFT MARKET PLACE": "Αγορά NFT",
  "Unique collections": "μοναδική συλλογή",
  "Launch at the same time as the project": "Κυκλοφόρησε ταυτόχρονα με το έργο",
  "NFT Box and Airdrop": "Κιβώτιο NFT και airdrop",
  "DISCOVER NFT": "Εξερευνήστε τα NFT",
  "AI-Powerde Content": "Περιεχόμενο που βασίζεται σε AI",
  "AI Image Generator": "Γεννήτρια εικόνας AI",
  "AI Chat Bot": "Ρομπότ συνομιλίας AI",
  "AI Code": "Κωδικός AI",
  "COIN SWAP": "Ανταλλαγή νομισμάτων",
  "ELONXCAT ALLOCAT STASTISTICS": "Στατιστικά στοιχεία κατανομής ELONXCAT",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "Η υπηρεσία πονταρίσματος ELONXCAT προσεχώς",
  "WELCOME TO ELONXCAT STAKING": "Καλωσορίσατε στο ELONXCAT Staking",
  "WITHDRAW STAKED TOKENS": "Αποσύρετε τα πονταρισμένα μάρκες",
  "STAKED BALANCE": "Υπόλοιπο πονταρίσματος",
  "YOUR STAKEABLE": "Υπόλοιπο διαθέσιμο για ποντάρισμα",
  "TOTAL STAKED": "συνολικό ποντάρισμα",
  "ESTIMATED REWARDS": "αναμενόμενη ανταμοιβή",
  "Rewards rate is dynamic": "Η αναλογία ανταμοιβής είναι δυναμική",
  "Monthly": "μηνιαίος",
  "Daily": "ματιά",
  "CURRENT REWARDS": "τρέχουσα ανταμοιβή",
  "TOTAL REWARDS": "συνολική ανταμοιβή",
  "CLAIM REWARDS": "αξίωση αποζημίωσης",
  "TOTAL SUPPLY": "συνολική προσφορά",
  "OUR PARTNERS and BACKERS": "Οι συνεργάτες και οι χορηγοί μας",
  "ELONXCAT TEAM": "Ομάδα ELONXCAT",
  "Founder": "ιδρυτής",
  "Creative Director": "δημιουργικός διευθυντής",
  "Community manger": "διαχειριστής κοινότητας",
  "CONNECT YOUR WALLET": "Σύνδεση πορτοφολιού",
  "ENTER PRESALE": "Συμμετοχή στην προπώληση",
  "CLAIM ELONXCAT": "Χρέωση ELONXCAT",
  "ROAD MAP": "οδικός χάρτης",
  "DEVELOPMENT": "ανάπτυξη",
  "MEME DOMINATION": "Δωρεά Meme",
  "FREQUENTLY ASKED QUESTIONS": "Συχνές Ερωτήσεις",
  "What is a presale": "Τι είναι η προπώληση;",
  "What is ELONXCAT": "Τι είναι το ELONXCAT;",
  "How do I participate in the presale": "Πώς συμμετέχω στην προπώληση;",
  "When do I receive my presale tokens": "Πότε θα λάβω τα διακριτικά μου προπώλησης;",
  "What is ELONXCAT staking": "Τι ποντάρει η ELONXCAT;",
  "How do I stake my tokens": "Πώς ποντάρω τα κουπόνια μου;",
  "I staked my tokens during presale": "Πόνταρα μάρκες κατά την προπώληση, πότε μπορώ να τα διεκδικήσω;",
  "STAY CONNECTED": "μείνετε συνδεδεμένοι",
  "PRIVACY POLICY": "Πολιτική Απορρήτου",
  "HOW TO BUY": "Πώς να αγοράσετε",
  "TERMS and CONDITIONS": "Όροι Χρήσης",
  "PRESALE ALLOCATION": "Κατανομή προπώλησης",
  "AIRDROP": "αεροπορική πτώση",
  "PROJECTFUNDS": "χρηματοδότηση έργου",
  "LIQUIDITY": "ρευστότητα",
  "MARKETING": "εμπορία",
  "EXCHANGES": "ανταλλαγή",
  "ECOSYSTEM FUNDS": "ταμεία οικοσυστήματος",
  "EXPANSION": "επέκταση",

  "texts": {
    "txt_1": "Η δική μας τεχνολογία που ονομάζεται X υποστηρίζει αυτήν την πολυαλυσίδα. Το X υποστηρίζει τη λειτουργία μετεγκατάστασης αλυσίδων, επιτρέποντας στους κατόχους του ELONXCAT να μετακινούνται ελεύθερα στις κορυφαίες αλυσίδες κρυπτονομισμάτων.",
    "txt_2": "Το ELONXCAT είναι ένα meme token χτισμένο σε ένα δίκτυο πολλαπλών αλυσίδων που εκτείνεται σε αλυσίδα BNB, Ethereum, Polygon, Solana και Tron.",
    "txt_3": "Το ELONXCAT προσπαθεί να ενώσει την κοινότητα των μιμιδίων κρυπτονομισμάτων και να γίνει πρωτοπόρος σε βοηθητικά προγράμματα πολλαπλών αλυσίδων.",
    "txt_4": "Στόχος μας είναι να λάμπει το όνομά μας σε κάθε DEX, κάθε CEX και οπουδήποτε αλλού.",
    "txt_5": "Αξιοποιώντας",
    "txt_6": "Η X θα συνεχίσει να επεκτείνει αυτές τις πολυαλυσίδες στο μέλλον.",
  },
  "PRESALE TIERS": "στάδιο προπώλησης",

  "tiers": {
    "tier_txt_1": "Η αρχική τιμή προπώλησης είναι 0,0000009 USD ανά θέση, η οποία θα αλλάξει σε Tier2 μετά από 20 ημέρες.",
    "tier_txt_2": "Η τιμή αυτού του επιπέδου ορίζεται στα 0,000001 USD από την προπώληση, η οποία θα αλλάξει σε Tier3 μετά από 20 ημέρες.",
    "tier_txt_3": "Η τιμή αυτού του επιπέδου ορίζεται στα 0,000005 USD από την προπώληση, η οποία θα αλλάξει στην τελική τιμή μετά από 20 ημέρες.",
    "tier_txt_4": "Η τιμή προπώλησης αυτού του επιπέδου είναι 0,000009 USD, η οποία θα τελειώσει με το τέλος της προπώλησης ELONXCAT σε αυτήν την τιμή.",
    "tier_txt_5": "Το ELONXCAT στοχεύει να είναι το καλύτερο DEX στο BSC με χαρακτηριστικά όπως ανταλλαγές συμβολαίων, ομάδες ρευστότητας και γεωργία απόδοσης.",
    "tier_txt_6": "Το ELONXCAT Quest συνδυάζει gaming και κρυπτονομίσματα, δίνοντάς σας την ευκαιρία να κερδίσετε μάρκες Lope μέσω αποστολών και παζλ στον κόσμο του blockchain.",
    "tier_txt_7": "Το έργο ELONXCAT NFT συνδυάζει την ψηφιακή τέχνη με τα memecoins ELONXCAT για να παρέχει μοναδικά, πλούσια σε χρησιμότητα συλλεκτικά αντικείμενα για την ενίσχυση της δέσμευσης της κοινότητας.",
    "tier_txt_8": "Το ELONXCAT συνδυάζει τη χρηματοδότηση και τη δημιουργικότητα σε μια φιλική προς τον χρήστη πλατφόρμα ενσωματώνοντας τεχνητή νοημοσύνη για συνομιλίες, συμβουλές συναλλαγών, δημιουργία μιμιδίων και πολλά άλλα.",
    "tier_txt_9": "Το ELONXCAT Quest είναι ένα παιχνίδι blockchain στο οικοσύστημα ELONXCAT Coin, που συνδυάζει gaming και κρυπτονομίσματα σε έναν ψηφιακό κόσμο εμπνευσμένο από τη μασκότ της γάτας. Διαθέτοντας αποστολές, παζλ και προκλήσεις PVP/PVE, οι παίκτες μπορούν να κερδίσουν μάρκες Lope μέσω περιπέτειας, στρατηγικής και κοινωνικής αλληλεπίδρασης. Το παιχνίδι στοχεύει να εισαγάγει τους χρήστες στο blockchain και να δημιουργήσει μια κοινότητα μέσω λειτουργιών για πολλούς παίκτες.",
    "tier_txt_10": "Το ELONXCAT Coin NFT Marketplace διαθέτει μια μοναδική συλλογή με ιδιαίτερα και μοναδικά χαρακτηριστικά.",
    "tier_txt_11": "Τα νομίσματα NFT της ELONXCAT θα εισαχθούν μαζί με το έργο σε όλες τις παγκόσμιες αγορές καθώς και στην αγορά του ίδιου του έργου.",
    "tier_txt_12": "Η αγορά περιλαμβάνει ένα μεγάλο πρόγραμμα airdrop που θα ενεργοποιηθεί κατά την προπώληση του κιβωτίου NFT και του token.",
    "tier_txt_13": "Δημιουργήστε ιστολόγια βελτιστοποιημένα για SEO, μηνύματα ηλεκτρονικού ταχυδρομείου πωλήσεων και πολλά άλλα... Εξοικονομήστε χρόνο και λάβετε εξαιρετικά αποτελέσματα. Τέλος, υπάρχει ένα πραγματικά χρησιμοποιήσιμο εργαλείο συγγραφής.",
    "tier_txt_14": "Οραματιστείτε αυτό που ονειρεύεστε. Δημιουργήστε εικόνες με κείμενο. Δημιουργήστε αμέσως εκπληκτικές εικόνες για την καλλιτεχνική σας καινοτομία με τη γεννήτρια εικόνας AI.",
    "tier_txt_15": "Απλώς επιλέξτε τον βοηθό σας και συνομιλήστε με ένα φιλικό chatbot για να δημιουργήσετε ιδέες, να βελτιώσετε το περιεχόμενό σας και μερικές φορές ακόμη και να γελάσετε.",
    "tier_txt_16": "Είστε έτοιμοι να γράψετε κώδικα με ταχύτητα αστραπής; Κωδικοποιήστε εύκολα με το AI Code Generator: Παρέχει άμεσες λύσεις στις προγραμματιστικές σας ανάγκες.",
    "tier_txt_17": "Το ELONXCAT Swap στοχεύει να είναι η κορυφαία πλατφόρμα αποκεντρωμένης ανταλλαγής (DEX) στο Binance Smart Chain (BSC).",
    "tier_txt_18": "Το ELONXCAT Swap παρέχει απρόσκοπτες ανταλλαγές διακριτικών, παρέχει δεξαμενές ρευστότητας και εισάγει καινοτόμα χαρακτηριστικά, όπως η καλλιέργεια απόδοσης και το ποντάρισμα για την ενίσχυση της ρευστότητας, τη διασφάλιση δίκαιων συναλλαγών και την προώθηση της συνολικής ανάπτυξης της κοινότητας του ELONXCAT.",
    "tier_txt_19": "Η διανομή των ανταμοιβών διακριτικού ELONXCAT θα γίνει σε διάστημα δύο ετών με το ποσοστό των 708,72 $ELONXCAT ανά μπλοκ Ethereum και μπορεί να διεκδικηθεί μόλις ενεργοποιηθεί η αξίωση.",
    "tier_txt_20": "Συνδέστε το πορτοφόλι σας σε δευτερόλεπτα χρησιμοποιώντας το Metamask ή το Trust Wallet.",
    "tier_txt_21": "Αποφασίστε πόσα $ELONXCAT θέλετε να αγοράσετε.  Μπορείτε να αγοράσετε με USDT μαζί με BNB, ETH και USDT.",
    "tier_txt_22": "Μόλις λήξει η προπώληση, μπορείτε να διεκδικήσετε τα $ELONXCAT token σας μέσω του ίδιου πορτοφολιού και της ίδιας αλυσίδας που αγοράζατε.",
    "tier_txt_23": "Η γέννηση του ELONMUSK &",
    "tier_txt_24": "Κτίριο κοινότητας Δημιουργία διακριτικών Κοινωνικά κανάλια Γέννηση",
    "tier_txt_25": "KYC & Audit Marketing Pump Ξεκινά η προπώληση",
    "tier_txt_26": "Έναρξη μάρκετινγκ CoinMarketCap  Αίτηση Αίτηση για CoinGecko Ενημέρωση ElonXCAT NFT Ανάπτυξη παιχνιδιών ElonXCAT Ενημέρωση πονταρίσματος",
    "tier_txt_27": "Πρώτη καταχώριση DEX  Ενημέρωση DEXTools  Ενημέρωση ElonXCAT AI Αυτόματη εγγραφή",
    "tier_txt_28": "Διαρκές μάρκετινγκ Κίνητρα κοινότητας Συνεργασίες με άλλες πλατφόρμες Αριθμός-στόχος κατόχων: 1 εκατομμύριο+",
    "tier_txt_29": "Οι προπωλήσεις παρέχουν μια μοναδική ευκαιρία στους επενδυτές να αγοράσουν νέα διακριτικά ή κρυπτονομίσματα πριν από την επίσημη κυκλοφορία τους. Αυτή η φάση πρώιμης πρόσβασης μας επιτρέπει να αξιοποιήσουμε τις δυνατότητες του έργου από την αρχή.",
    "tier_txt_30": "Το ELONXCAT είναι ένα πραγματικά προηγμένο μιμίδιο πολλαπλών αλυσίδων και είναι στην πραγματικότητα το ELONMUSK & Advanced Web3 Ecosystem: Staking | DEX | AI | παιχνίδια | αγορά NFT.",
    "tier_txt_31": "Μπορείτε να συμμετάσχετε στην προπώληση στην αλυσίδα BNB. Συνδέστε το Best Wallet ή άλλο πορτοφόλι στο παραπάνω γραφικό στοιχείο προπώλησης και κρατήστε μάρκες $ELONXCAT με BNB, ETH ή USDT.",
    "tier_txt_32": "Μόλις ολοκληρωθεί η αγορά προπώλησης, μπορείτε να χρησιμοποιήσετε το ίδιο πορτοφόλι για να διεκδικήσετε τα διακριτικά σας πριν από την πρώτη καταχώριση DEX. Οι ημερομηνίες χρέωσης και καταχώρισης θα ανακοινωθούν στους λογαριασμούς μας στα μέσα κοινωνικής δικτύωσης.",
    "tier_txt_33": "Το ποντάρισμα είναι η πράξη της αποστολής κουπονιών σε ένα έξυπνο συμβόλαιο για να τα κλειδώσετε για ένα ορισμένο χρονικό διάστημα. Αυτό σας επιτρέπει να κερδίζετε περισσότερα κουπόνια $ELONXCAT με την πάροδο του χρόνου με δυναμικό ρυθμό. Το ποντάρισμα ELONXCAT θα είναι σύντομα διαθέσιμο στο δίκτυο BNB. Για περισσότερες πληροφορίες, κάντε κλικ στο «Ποντάρισμα» στη γραμμή πλοήγησης.",
    "tier_txt_34": "Για να ποντάρετε $ELONXCAT, επισκεφτείτε εδώ: https://ELONXCAT/en/staking Το ποντάρισμα είναι δυνατό μόνο με $ELONXCAT που αγοράζεται στο BNB. Θα χρειαστείτε $BNB στο πορτοφόλι σας για να διεκπεραιώσετε τις αξιώσεις ανταμοιβής αφαίρεσης πονταρίσματος και πονταρίσματος.",
    "tier_txt_35": "Εάν έχετε ποντάρει $ELONXCAT, πρέπει να περιμένετε μια περίοδο κατοχύρωσης τουλάχιστον 14 ημερών προτού το διεκδικήσετε. Το ποντάρισμα είναι δυνατό μόνο με $BNB που αγοράζονται στο Ethereum. Θα χρειαστείτε $BNB στο πορτοφόλι σας για να διεκπεραιώσετε τις αξιώσεις ανταμοιβής αφαίρεσης πονταρίσματος και πονταρίσματος.",
    "tier_txt_36": "Το ποντάρισμα είναι η πράξη της αποστολής κουπονιών σε ένα έξυπνο συμβόλαιο για να τα κλειδώσετε για ένα ορισμένο χρονικό διάστημα. Αυτό σας επιτρέπει να κερδίζετε περισσότερα κουπόνια $ELONXCAT με την πάροδο του χρόνου με δυναμικό ρυθμό. Το ποντάρισμα ELONXCAT θα είναι σύντομα διαθέσιμο στο δίκτυο BNB. Για περισσότερες πληροφορίες, κάντε κλικ στο «Ποντάρισμα» στη γραμμή πλοήγησης.",
    "tier_txt_37": "Για να ποντάρετε $ELONXCAT, επισκεφτείτε εδώ: https://ELONXCAT/en/staking Το ποντάρισμα είναι δυνατό μόνο με $ELONXCAT που αγοράζεται στο BNB. Θα χρειαστείτε $BNB στο πορτοφόλι σας για να διεκπεραιώσετε τις αξιώσεις ανταμοιβής αφαίρεσης πονταρίσματος και πονταρίσματος.",
    "tier_txt_38": "Εάν έχετε ποντάρει $ELONXCAT, πρέπει να περιμένετε μια περίοδο κατοχύρωσης τουλάχιστον 14 ημερών προτού το διεκδικήσετε. Το ποντάρισμα είναι δυνατό μόνο με $BNB που αγοράζονται στο Ethereum. Θα χρειαστείτε $BNB στο πορτοφόλι σας για να διεκπεραιώσετε τις αξιώσεις ανταμοιβής αφαίρεσης πονταρίσματος και πονταρίσματος.",
    "tier_txt_39": "ELONXCAT OFFICE PANAMA: 43ος όροφος, Oceania BusinessPlaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, Πόλη του Παναμά, Παναμάς",
    "tier_txt_40": "Αποποίηση ευθύνης: Το ELONXCAT είναι ένα μιμίδιο, η αξία του βασίζεται αποκλειστικά στο παγκόσμιο πολιτιστικό κεφάλαιο των μιμιδίων ELONXCAT. Η αξία ενός κρυπτονομίσματος μπορεί να αυξηθεί ή να μειωθεί. Επενδύστε προσεκτικά."
  }
}