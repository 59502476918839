export default {
  "ABOUT": "Einführung",
  "TOKENOMICS": "Tokenomics",
  "STAKING": "Abstecken",
  "HOW TO BUY": "So kaufen Sie ein",
  "ROADMAP": "Roadmap",
  "FAQ": "Häufig gestellte Fragen",
  "BUY NOW": "Jetzt kaufen",
  "ELONXCAT is the real": "ELONXCAT ist echt",
  "ELONMUSK": "Elon Musk",
  "SpaceX and X": "SpaceX und",
  "meme project": "Meme-Projekt",
  "Advanced Web3 Ecosystem": "Erweitertes Web3-Ökosystem",
  "Potential of project": "Projektpotenzial",
  "Market cap Target": "angestrebte Marktkapitalisierung",
  "Investors": "Investoren",
  "WHITE PAPER": "weißes Buch",
  "AUDIT": "Danke",
  "Presale will start soon. Join the Presale": "Der Vorverkauf beginnt bald. Nehmen Sie am Vorverkauf teil",
  "TOKEN DISTRIBUTION DATE": "Datum der Token-Verteilung",
  "Raised": "Betrag erhoben",
  "ELONXCAT sold": "ELONXCAT-Verkaufsvolumen",
  "BUY": "kaufen",
  "MULTI BRIDGE UTILITY": "Multiple Bridge-Dienstprogramm",
  "Total Supply": "Gesamtangebot",
  "ELONXCAT ACHIEVEMENTS": "ELONXCAT-Leistung",
  "Ecosystem Percentage": "Ökosystemverhältnis",
  "ELONXCAT QUEST GAME": "ELONXCAT Quest-Spiel",
  "ELONXCAT GAME PORTAL SOON": "ELONXCAT-Gaming-Portal bald verfügbar",
  "NFT MARKET PLACE": "NFT-Marktplatz",
  "Unique collections": "einzigartige Sammlung",
  "Launch at the same time as the project": "Gleichzeitig mit dem Projekt veröffentlicht",
  "NFT Box and Airdrop": "NFT-Box und Airdrop",
  "DISCOVER NFT": "Entdecken Sie NFTs",
  "AI-Powerde Content": "KI-basierte Inhalte",
  "AI Image Generator": "KI-Bildgenerator",
  "AI Chat Bot": "KI-Chatbot",
  "AI Code": "KI-Code",
  "COIN SWAP": "Münztausch",
  "ELONXCAT ALLOCAT STASTISTICS": "ELONXCAT-Zuteilungsstatistik",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "Der ELONXCAT-Absteckdienst ist bald verfügbar",
  "WELCOME TO ELONXCAT STAKING": "Willkommen beim ELONXCAT Staking",
  "WITHDRAW STAKED TOKENS": "Abgesteckte Token abheben",
  "STAKED BALANCE": "Einsatzguthaben",
  "YOUR STAKEABLE": "Guthaben zum Abstecken verfügbar",
  "TOTAL STAKED": "Gesamteinsatz",
  "ESTIMATED REWARDS": "erwartete Belohnung",
  "Rewards rate is dynamic": "Das Belohnungsverhältnis ist dynamisch",
  "Monthly": "monatlich",
  "Daily": "Blick",
  "CURRENT REWARDS": "aktuelle Belohnung",
  "TOTAL REWARDS": "Gesamtbelohnung",
  "CLAIM REWARDS": "Schadensersatzanspruch",
  "TOTAL SUPPLY": "Gesamtangebot",
  "OUR PARTNERS and BACKERS": "Unsere Partner und Sponsoren",
  "ELONXCAT TEAM": "ELONXCAT-Team",
  "Founder": "Gründer",
  "Creative Director": "Kreativdirektor",
  "Community manger": "Community-Manager",
  "CONNECT YOUR WALLET": "Wallet-Verbindung",
  "ENTER PRESALE": "Teilnahme am Vorverkauf",
  "CLAIM ELONXCAT": "ELONXCAT-Abrechnung",
  "ROAD MAP": "Roadmap",
  "DEVELOPMENT": "Entwicklung",
  "MEME DOMINATION": "Meme-Spende",
  "FREQUENTLY ASKED QUESTIONS": "Häufig gestellte Fragen",
  "What is a presale": "Was ist ein Vorverkauf?",
  "What is ELONXCAT": "Was ist ELONXCAT?",
  "How do I participate in the presale": "Wie nehme ich am Vorverkauf teil?",
  "When do I receive my presale tokens": "Wann erhalte ich meine Vorverkaufs-Tokens?",
  "What is ELONXCAT staking": "Was ist ELONXCAT-Einsatz?",
  "How do I stake my tokens": "Wie setze ich meine Token ein?",
  "I staked my tokens during presale": "Ich habe während des Vorverkaufs Token eingesetzt. Wann kann ich sie einfordern?",
  "STAY CONNECTED": "Bleiben Sie in Verbindung",
  "PRIVACY POLICY": "Datenschutzrichtlinie",
  "HOW TO BUY": "So kaufen Sie ein",
  "TERMS and CONDITIONS": "Nutzungsbedingungen",
  "PRESALE ALLOCATION": "Zuteilung vor dem Verkauf",
  "AIRDROP": "Luftabwurf",
  "PROJECTFUNDS": "Projektfinanzierung",
  "LIQUIDITY": "Liquidität",
  "MARKETING": "Marketing",
  "EXCHANGES": "Austausch",
  "ECOSYSTEM FUNDS": "Ökosystemfonds",
  "EXPANSION": "Erweiterung",

  "texts": {
    "txt_1": "Unsere eigene Technologie namens X unterstützt diese Multichain. X unterstützt die Kettenmigrationsfunktionalität und ermöglicht es ELONXCAT-Inhabern, sich frei über führende Kryptowährungsketten zu bewegen.",
    "txt_2": "ELONXCAT ist ein Meme-Token, der auf einem Multichain-Netzwerk basiert, das die BNB-Kette, Ethereum, Polygon, Solana und Tron umfasst.",
    "txt_3": "ELONXCAT ist bestrebt, die Kryptowährungs-Meme-Community zu vereinen und ein Pionier für Multi-Chain-Dienstprogramme zu werden.",
    "txt_4": "Unser Ziel ist es, dass unser Name auf jedem DEX, jedem CEX und überall sonst glänzt.",
    "txt_5": "Nutzen",
    "txt_6": "X wird diese Multichains auch in Zukunft weiter ausbauen.",
  },
  "PRESALE TIERS": "Vorverkaufsphase",

  "tiers": {
    "tier_txt_1": "Der anfängliche Vorverkaufspreis beträgt 0,0000009 USD pro Sitzplatz, der sich nach 20 Tagen in Tier2 ändert.",
    "tier_txt_2": "Der Preis dieser Stufe ist ab dem Vorverkauf auf 0,000001 USD festgelegt, der sich nach 20 Tagen in Tier3 ändert.",
    "tier_txt_3": "Der Preis dieser Stufe ist ab dem Vorverkauf auf 0,000005 USD festgelegt, der sich nach 20 Tagen in den Endpreis ändert.",
    "tier_txt_4": "Der Vorverkaufspreis dieser Stufe beträgt 0,000009 USD, der mit dem Ende des ELONXCAT-Vorverkaufs zu diesem Preis endet.",
    "tier_txt_5": "ELONXCAT zielt darauf ab, der beste DEX auf BSC zu sein, mit Funktionen wie Token-Swaps, Liquiditätspools und Yield Farming.",
    "tier_txt_6": "ELONXCAT Quest kombiniert Gaming und Kryptowährung und bietet Ihnen die Möglichkeit, durch Quests und Rätsel in der Blockchain-Welt Lope-Tokens zu verdienen.",
    "tier_txt_7": "Das ELONXCAT NFT-Projekt kombiniert digitale Kunst mit ELONXCAT-Memecoins, um einzigartige, nützliche Sammlerstücke bereitzustellen, die das Engagement der Community fördern.",
    "tier_txt_8": "ELONXCAT vereint Finanzen und Kreativität in einer benutzerfreundlichen Plattform, indem es KI für Chats, Handelstipps, Meme-Erstellung und mehr integriert.",
    "tier_txt_9": "ELONXCAT Quest ist ein Blockchain-Spiel innerhalb des ELONXCAT Coin-Ökosystems, das Gaming und Kryptowährung in einer digitalen Welt kombiniert, die vom Katzenmaskottchen inspiriert ist. Mit Quests, Rätseln und PVP/PVE-Herausforderungen können Spieler durch Abenteuer, Strategie und soziale Interaktion Lope-Token verdienen. Das Spiel zielt darauf ab, Benutzer an die Blockchain heranzuführen und durch Multiplayer-Funktionen eine Community aufzubauen.",
    "tier_txt_10": "Der ELONXCAT Coin NFT Marketplace verfügt über eine einzigartige Sammlung mit besonderen und einzigartigen Funktionen.",
    "tier_txt_11": "ELONXCAT Coin NFTs werden zusammen mit dem Projekt auf allen globalen Märkten sowie auf dem eigenen Markt des Projekts gelistet.",
    "tier_txt_12": "Der Markt umfasst ein großes Airdrop-Programm, das während des NFT-Box- und Token-Vorverkaufs aktiviert wird.",
    "tier_txt_13": "Erstellen Sie SEO-optimierte Blogs, Verkaufs-E-Mails und mehr ... Sparen Sie Zeit und erzielen Sie großartige Ergebnisse. Endlich gibt es ein wirklich brauchbares Autorentool.",
    "tier_txt_14": "Visualisieren Sie, wovon Sie träumen. Erstellen Sie Bilder mit Text. Erstellen Sie mit unserem KI-Bildgenerator sofort atemberaubende Bilder für Ihre künstlerische Innovation.",
    "tier_txt_15": "Wählen Sie einfach Ihren Assistenten aus und chatten Sie mit einem freundlichen Chatbot, um Ideen zu generieren, Ihre Inhalte zu verbessern und manchmal sogar für Lacher zu sorgen.",
    "tier_txt_16": "Sind Sie bereit, blitzschnell Code zu schreiben? Programmieren Sie ganz einfach mit dem AI Code Generator: Bietet sofortige Lösungen für Ihre Programmieranforderungen.",
    "tier_txt_17": "ELONXCAT Swap möchte die führende dezentrale Austauschplattform (DEX) auf der Binance Smart Chain (BSC) sein.",
    "tier_txt_18": "ELONXCAT Swap bietet nahtlose Token-Swaps, stellt Liquiditätspools bereit und führt innovative Funktionen wie Yield Farming und Staking ein, um die Liquidität zu erhöhen, einen fairen Handel zu gewährleisten und das Gesamtwachstum der ELONXCAT-Community zu fördern.",
    "tier_txt_19": "Die Verteilung der ELONXCAT-Token-Belohnungen erfolgt über einen Zeitraum von zwei Jahren in Höhe von 708,72 $ELONXCAT pro Ethereum-Block und kann eingefordert werden, sobald der Anspruch aktiviert ist.",
    "tier_txt_20": "Verbinden Sie Ihr Wallet in Sekundenschnelle mit Metamask oder Trust Wallet.",
    "tier_txt_21": "Entscheiden Sie, wie viel $ELONXCAT Sie kaufen möchten.  Sie können mit USDT zusammen mit BNB, ETH und USDT kaufen.",
    "tier_txt_22": "Sobald der Vorverkauf endet, können Sie Ihre $ELONXCAT-Token über dasselbe Wallet und dieselbe Kette anfordern, die Sie für den Kauf verwendet haben.",
    "tier_txt_23": "Die Geburt von ELONMUSK &",
    "tier_txt_24": "Gemeinschaftsaufbau Token-Generierung Soziale Kanäle Geburt",
    "tier_txt_25": "KYC & Audit Marketing Pump Der Vorverkauf beginnt",
    "tier_txt_26": "Beginnen Sie mit dem Marketing CoinMarketCap  Bewerben Beantragen Sie CoinGecko ElonXCAT NFT-Update ElonXCAT-Spieleentwicklung Staking-Update",
    "tier_txt_27": "Erstes DEX-Listing  DEXTools-Update  ElonXCAT AI-Update Automatisches Brennen",
    "tier_txt_28": "Laufendes Marketing Community-Incentives Partnerschaften mit anderen Plattformen Zielanzahl der Inhaber: 1 Million+",
    "tier_txt_29": "Vorverkäufe bieten Anlegern eine einzigartige Gelegenheit, neue Token oder Kryptowährungen vor ihrer offiziellen Einführung zu erwerben. Diese Early-Access-Phase ermöglicht es uns, das Potenzial des Projekts von Anfang an auszuschöpfen.",
    "tier_txt_30": "ELONXCAT ist eine wirklich fortschrittliche Multi-Chain-Meme-Münze und ist eigentlich die ELONMUSK & Erweitertes Web3-Ökosystem: Abstecken | DEX | KI | Spiele | NFT-Markt.",
    "tier_txt_31": "Sie können am Vorverkauf der BNB-Kette teilnehmen. Verbinden Sie Best Wallet oder ein anderes Wallet mit dem Vorverkaufs-Widget oben und reservieren Sie $ELONXCAT-Tokens bei BNB, ETH oder USDT.",
    "tier_txt_32": "Sobald Ihr Vorverkaufskauf abgeschlossen ist, können Sie dieselbe Wallet verwenden, um Ihre Token vor der ersten DEX-Notierung einzufordern. Die Abrechnungs- und Listungstermine werden auf unseren Social-Media-Konten bekannt gegeben.",
    "tier_txt_33": "Beim Abstecken werden Token an einen Smart Contract gesendet, um sie für einen bestimmten Zeitraum zu sperren. Dadurch können Sie im Laufe der Zeit dynamisch mehr $ELONXCAT-Tokens verdienen. Das Abstecken von ELONXCAT wird bald im BNB-Netzwerk verfügbar sein. Für weitere Informationen klicken Sie in der Navigationsleiste auf „Abstecken“.",
    "tier_txt_34": "Um $ELONXCAT zu setzen, besuchen Sie hier: https://ELONXCAT/en/staking Das Abstecken ist nur mit auf BNB gekauften $ELONXCAT möglich. Sie benötigen $BNB in ​​Ihrem Wallet, um Abhebungs- und Absteckprämienansprüche zu bearbeiten.",
    "tier_txt_35": "Wenn Sie $ELONXCAT eingesetzt haben, müssen Sie eine Sperrfrist von mindestens 14 Tagen abwarten, bevor Sie es beanspruchen können. Das Abstecken ist nur mit auf Ethereum gekauften $BNB möglich. Sie benötigen $BNB in ​​Ihrem Wallet, um Abhebungs- und Absteckprämienansprüche zu bearbeiten.",
    "tier_txt_36": "Beim Abstecken werden Token an einen Smart Contract gesendet, um sie für einen bestimmten Zeitraum zu sperren. Dadurch können Sie im Laufe der Zeit dynamisch mehr $ELONXCAT-Tokens verdienen. Das Abstecken von ELONXCAT wird bald im BNB-Netzwerk verfügbar sein. Für weitere Informationen klicken Sie in der Navigationsleiste auf „Abstecken“.",
    "tier_txt_37": "Um $ELONXCAT zu setzen, besuchen Sie hier: https://ELONXCAT/en/staking Das Abstecken ist nur mit auf BNB gekauften $ELONXCAT möglich. Sie benötigen $BNB in ​​Ihrem Wallet, um Abhebungs- und Absteckprämienansprüche zu bearbeiten.",
    "tier_txt_38": "Wenn Sie $ELONXCAT eingesetzt haben, müssen Sie eine Sperrfrist von mindestens 14 Tagen abwarten, bevor Sie es beanspruchen können. Das Abstecken ist nur mit auf Ethereum gekauften $BNB möglich. Sie benötigen $BNB in ​​Ihrem Wallet, um Abhebungs- und Absteckprämienansprüche zu bearbeiten.",
    "tier_txt_39": "ELONXCAT-BÜRO PANAMA: 43. Etage, Oceania BusinessPlaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, Panama City, Panama",
    "tier_txt_40": "Haftungsausschluss: ELONXCAT ist eine Meme-Münze, ihr Wert basiert ausschließlich auf dem universellen Kulturkapital der ELONXCAT-Memes. Der Wert einer Kryptowährung kann steigen oder fallen. Investieren Sie sorgfältig."
  }
}