export default {
  "ABOUT": "úvod",
  "TOKENOMICS": "Tokenomika",
  "STAKING": "stávkovanie",
  "HOW TO BUY": "Ako nakupovať",
  "ROADMAP": "cestovná mapa",
  "FAQ": "Často kladené otázky",
  "BUY NOW": "kúpiť teraz",
  "ELONXCAT is the real": "ELONXCAT je skutočný",
  "ELONMUSK": "Elon Musk",
  "SpaceX and X": "SpaceX a",
  "meme project": "meme projekt",
  "Advanced Web3 Ecosystem": "Pokročilý ekosystém Web3",
  "Potential of project": "Potenciál projektu",
  "Market cap Target": "cieľová trhová kapitalizácia",
  "Investors": "investorov",
  "WHITE PAPER": "biela kniha",
  "AUDIT": "dakujem",
  "Presale will start soon. Join the Presale": "Predpredaj začne čoskoro. Zúčastnite sa predpredaja",
  "TOKEN DISTRIBUTION DATE": "Dátum distribúcie tokenu",
  "Raised": "Vyzbieraná suma",
  "ELONXCAT sold": "Objem predaja ELONXCAT",
  "BUY": "nákup",
  "MULTI BRIDGE UTILITY": "Multiple Bridge Utility",
  "Total Supply": "celková zásoba",
  "ELONXCAT ACHIEVEMENTS": "Výkon ELONXCAT",
  "Ecosystem Percentage": "ekosystémový pomer",
  "ELONXCAT QUEST GAME": "Úlohová hra ELONXCAT",
  "ELONXCAT GAME PORTAL SOON": "Herný portál ELONXCAT už čoskoro",
  "NFT MARKET PLACE": "Trhovisko NFT",
  "Unique collections": "jedinečná kolekcia",
  "Launch at the same time as the project": "Vydané súčasne s projektom",
  "NFT Box and Airdrop": "NFT box a airdrop",
  "DISCOVER NFT": "Preskúmajte NFT",
  "AI-Powerde Content": "Obsah založený na AI",
  "AI Image Generator": "AI Image Generator",
  "AI Chat Bot": "AI chatovací robot",
  "AI Code": "AI kód",
  "COIN SWAP": "Výmena mincí",
  "ELONXCAT ALLOCAT STASTISTICS": "Štatistika prideľovania ELONXCAT",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "Staking Service ELONXCAT už čoskoro",
  "WELCOME TO ELONXCAT STAKING": "Vitajte v ELONXCAT Staking",
  "WITHDRAW STAKED TOKENS": "Vyberte vsadené žetóny",
  "STAKED BALANCE": "Staking Balance",
  "YOUR STAKEABLE": "Zostatok k dispozícii na stávkovanie",
  "TOTAL STAKED": "celkové stávkovanie",
  "ESTIMATED REWARDS": "očakávaná odmena",
  "Rewards rate is dynamic": "Pomer odmien je dynamický",
  "Monthly": "mesačne",
  "Daily": "pohľad",
  "CURRENT REWARDS": "aktuálna odmena",
  "TOTAL REWARDS": "celková odmena",
  "CLAIM REWARDS": "nárok na odškodnenie",
  "TOTAL SUPPLY": "celková zásoba",
  "OUR PARTNERS and BACKERS": "Naši partneri a sponzori",
  "ELONXCAT TEAM": "Tím ELONXCAT",
  "Founder": "zakladateľ",
  "Creative Director": "kreatívny riaditeľ",
  "Community manger": "komunitný manažér",
  "CONNECT YOUR WALLET": "Pripojenie k peňaženke",
  "ENTER PRESALE": "Účasť v predpredaji",
  "CLAIM ELONXCAT": "Fakturácia ELONXCAT",
  "ROAD MAP": "cestovná mapa",
  "DEVELOPMENT": "rozvoj",
  "MEME DOMINATION": "Darovanie meme",
  "FREQUENTLY ASKED QUESTIONS": "Často kladené otázky",
  "What is a presale": "Čo je to predpredaj?",
  "What is ELONXCAT": "Čo je ELONXCAT?",
  "How do I participate in the presale": "Ako sa zapojím do predpredaja?",
  "When do I receive my presale tokens": "Kedy dostanem predpredajové žetóny?",
  "What is ELONXCAT staking": "Čo je stávkovanie ELONXCAT?",
  "How do I stake my tokens": "Ako vsadím svoje žetóny?",
  "I staked my tokens during presale": "Vsadil som žetóny počas predpredaja, kedy si ich môžem uplatniť?",
  "STAY CONNECTED": "zostať v spojení",
  "PRIVACY POLICY": "Zásady ochrany osobných údajov",
  "HOW TO BUY": "Ako nakupovať",
  "TERMS and CONDITIONS": "Podmienky používania",
  "ROADMAP": "cestovná mapa",
  "PRESALE ALLOCATION": "Predpredajová alokácia",
  "AIRDROP": "výsadku",
  "PROJECTFUNDS": "financovanie projektu",
  "LIQUIDITY": "likvidita",
  "MARKETING": "marketing",
  "EXCHANGES": "výmena",
  "ECOSYSTEM FUNDS": "ekosystémové fondy",
  "EXPANSION": "rozšírenie",

  "texts": {
    "txt_1": "Naša vlastná technológia s názvom X podporuje tento multichain. X podporuje funkciu reťazovej migrácie, ktorá umožňuje držiteľom ELONXCAT voľne sa pohybovať naprieč poprednými kryptomenovými reťazcami.",
    "txt_2": "ELONXCAT je meme token postavený na viacreťazcovej sieti zahŕňajúcej reťazec BNB, Ethereum, Polygon, Solana a Tron.",
    "txt_3": "ELONXCAT sa snaží zjednotiť komunitu kryptomenových meme a stať sa priekopníkom v multi-reťazcových utilitách.",
    "txt_4": "Naším cieľom je, aby naše meno svietilo na každom DEX, každom CEX a všade inde.",
    "txt_5": "Využitie",
    "txt_6": "X bude pokračovať v rozširovaní týchto multichainov v budúcnosti.",
  },

  "PRESALE TIERS": "etapa predpredaja",
  "tiers": {
    "tier_txt_1": "Počiatočná cena predpredaja je 0,0000009 USD za sedadlo, ktorá sa po 20 dňoch zmení na Tier2.",
    "tier_txt_2": "Cena tejto úrovne je stanovená na 0,000001 USD od predpredaja, ktorá sa po 20 dňoch zmení na Tier3.",
    "tier_txt_3": "Cena tejto úrovne je stanovená na 0,000005 USD od predpredaja, ktorá sa po 20 dňoch zmení na konečnú cenu.",
    "tier_txt_4": "Cena predpredaja tejto úrovne je 0,000009 USD, ktorá skončí s koncom predpredaja ELONXCAT s touto cenou.",
    "tier_txt_5": "Cieľom ELONXCAT je byť najlepším DEX na BSC s funkciami, ako sú tokenové swapy, fondy likvidity a výnosové hospodárenie.",
    "tier_txt_6": "ELONXCAT Quest kombinuje hry a kryptomenu, čo vám dáva príležitosť zarobiť tokeny Lope prostredníctvom úloh a hádaniek vo svete blockchainu.",
    "tier_txt_7": "Projekt ELONXCAT NFT kombinuje digitálne umenie s memecoinmi ELONXCAT a poskytuje jedinečné zberateľské predmety bohaté na užitočnosť na zvýšenie zapojenia komunity.",
    "tier_txt_8": "ELONXCAT spája financie a kreativitu v užívateľsky prívetivej platforme integráciou AI pre chatovanie, obchodné tipy, vytváranie meme a ďalšie.",
    "tier_txt_9": "ELONXCAT Quest je blockchainová hra v rámci ekosystému ELONXCAT Coin, ktorá kombinuje hry a kryptomenu v digitálnom svete inšpirovanom maskotom mačky. Vďaka úlohám, hádankám a výzvam PVP/PVE môžu hráči získať žetóny Lope prostredníctvom dobrodružstva, stratégie a sociálnej interakcie. Cieľom hry je predstaviť používateľom blockchain a vybudovať komunitu prostredníctvom funkcií pre viacerých hráčov.",
    "tier_txt_10": "ELONXCAT Coin NFT Marketplace má jedinečnú kolekciu so špeciálnymi a jedinečnými funkciami.",
    "tier_txt_11": "ELONXCAT Coin NFT budú kótované spolu s projektom na všetkých globálnych trhoch, ako aj na vlastnom trhu projektu.",
    "tier_txt_12": "Trh obsahuje veľký airdrop program, ktorý bude aktivovaný počas predpredaja NFT boxu a tokenov.",
    "tier_txt_13": "Vytvárajte blogy optimalizované pre SEO, predajné e-maily a ďalšie... Ušetrite čas a získajte skvelé výsledky. Nakoniec je tu skutočne použiteľný autorský nástroj.",
    "tier_txt_14": "Vizualizujte si, o čom snívate. Vytvárajte obrázky s textom. Okamžite vytvorte úžasné obrázky pre svoju umeleckú inováciu pomocou nášho generátora obrázkov AI.",
    "tier_txt_15": "Jednoducho si vyberte svojho asistenta a chatujte s priateľským chatbotom, aby ste generovali nápady, vylepšovali svoj obsah a niekedy sa dokonca zasmiali.",
    "tier_txt_16": "Ste pripravení písať kód rýchlosťou blesku? Jednoduché kódovanie s AI Code Generator: Poskytuje okamžité riešenia vašich programovacích potrieb.",
    "tier_txt_17": "Cieľom ELONXCAT Swap je byť vedúcou platformou decentralizovanej burzy (DEX) na Binance Smart Chain (BSC).",
    "tier_txt_18": "ELONXCAT Swap poskytuje bezproblémové tokenové swapy, poskytuje fondy likvidity a zavádza inovatívne funkcie, ako je výnosové hospodárenie a stávkovanie na zvýšenie likvidity, zabezpečenie spravodlivého obchodovania a podporu celkového rastu komunity ELONXCAT.",
    "tier_txt_19": "Distribúcia odmien za token ELONXCAT sa uskutoční počas dvoch rokov vo výške 708,72 $ ELONXCAT za blok Ethereum a je možné si ich uplatniť po aktivácii nároku.",
    "tier_txt_20": "Pripojte svoju peňaženku v priebehu niekoľkých sekúnd pomocou Metamask alebo Trust Wallet.",
    "tier_txt_21": "Rozhodnite sa, koľko $ ELONXCAT chcete kúpiť.   Môžete nakupovať s USDT spolu s BNB, ETH a USDT.",
    "tier_txt_22": "Po skončení predpredaja si môžete uplatniť svoje tokeny $ELONXCAT prostredníctvom rovnakej peňaženky a reťazca, ktorý ste použili na nákup.",
    "tier_txt_23": "Zrodenie ELONMUSK &",
    "tier_txt_24": "Budovanie komunity  Generovanie tokenov  Sociálne kanály  Narodenie",
    "tier_txt_25": "KYC & Audit  Marketing Pump  Začína sa predpredaj",
    "tier_txt_26": "Začať marketing  CoinMarketCap   Požiadať  Požiadať o CoinGecko  Aktualizácia ElonXCAT NFT  Vývoj hry ElonXCAT  Aktualizácia stávok",
    "tier_txt_27": "Prvý zoznam DEX   Aktualizácia DEXTools   Aktualizácia ElonXCAT AI  Automatické napaľovanie",
    "tier_txt_28": "Pokračujúci marketing  Komunitné stimuly  Partnerstvá s inými platformami  Cieľový počet držiteľov: 1 milión+",
    "tier_txt_29": "Predpredaj poskytuje investorom jedinečnú príležitosť na nákup nových tokenov alebo kryptomien pred ich oficiálnym spustením. Táto fáza skorého prístupu nám umožňuje využiť potenciál projektu od samého začiatku.",
    "tier_txt_30": "ELONXCAT je skutočne pokročilá multireťazcová meme minca a je to vlastne ELONMUSK & Advanced Web3 Ecosystem: Staking | DEX | AI | hry | NFT trh.",
    "tier_txt_31": "Do predpredaja sa môžete zapojiť na reťazci BNB. Pripojte Best Wallet alebo inú peňaženku k predpredajovému widgetu vyššie a rezervujte si tokeny $ELONXCAT s BNB, ETH alebo USDT.",
    "tier_txt_32": "Po dokončení predpredajového nákupu môžete použiť tú istú peňaženku na nárokovanie svojich tokenov pred prvým výpisom DEX. Dátumy fakturácie a výpisu budú zverejnené na našich účtoch na sociálnych sieťach.",
    "tier_txt_33": "Staking je akt odoslania tokenov do inteligentnej zmluvy, ktorá ich uzamkne na určité časové obdobie. To vám umožní zarobiť viac tokenov $ELONXCAT v priebehu času dynamickou rýchlosťou. ELONXCAT staking bude čoskoro dostupný v sieti BNB. Pre viac informácií kliknite na „Staking“ v navigačnej lište.",
    "tier_txt_34": "Ak chcete staviť $ELONXCAT, navštívte tu: https://ELONXCAT/en/staking Stávkovanie je možné len s $ELONXCAT zakúpeným na BNB. Na spracovanie žiadostí o odmenu za zrušenie stávok a stávkovanie budete potrebovať vo svojej peňaženke $ BNB.",
    "tier_txt_35": "Ak ste vsadili $ELONXCAT, musíte pred uplatnením nároku počkať minimálne 14 dní. Stacking je možný len s $BNB zakúpeným v Ethereu. Na spracovanie žiadostí o odmenu za zrušenie stávok a stávkovanie budete potrebovať vo svojej peňaženke $ BNB.",
    "tier_txt_36": "Staking je akt odoslania tokenov do inteligentnej zmluvy, ktorá ich uzamkne na určité časové obdobie. To vám umožní zarobiť viac tokenov $ELONXCAT v priebehu času dynamickou rýchlosťou. ELONXCAT staking bude čoskoro dostupný v sieti BNB. Pre viac informácií kliknite na „Staking“ v navigačnej lište.",
    "tier_txt_37": "Ak chcete staviť $ELONXCAT, navštívte tu: https://ELONXCAT/en/staking Stávkovanie je možné len s $ELONXCAT zakúpeným na BNB. Na spracovanie žiadostí o odmenu za zrušenie stávok a stávkovanie budete potrebovať vo svojej peňaženke $ BNB.",
    "tier_txt_38": "Ak ste vsadili $ELONXCAT, musíte pred uplatnením nároku počkať minimálne 14 dní. Stacking je možný len s $BNB zakúpeným v Ethereu. Na spracovanie žiadostí o odmenu za zrušenie stávok a stávkovanie budete potrebovať vo svojej peňaženke $ BNB.",
    "tier_txt_39": "ELONXCAT OFFICE PANAMA: 43. poschodie, Oceánia Business Plaza, Tower 2000, Punta Colon Street, Urbanisación Punta  Pacifica, Panama City, Panama",
    "tier_txt_40": "Zrieknutie sa zodpovednosti: ELONXCAT je memová minca, jej hodnota je založená výlučne na univerzálnom kultúrnom kapitále mémov ELONXCAT.  Hodnota kryptomeny môže stúpať alebo klesať. Investujte opatrne."
  }
}