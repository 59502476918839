import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { WagmiProvider, cookieStorage, createStorage } from 'wagmi';
import { bsc } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();
const VITE_PROJECT_ID = 'adc44ed1cb9a9bdf0f9c5f85279b7c89';
const projectId = VITE_PROJECT_ID;

const metadata = {
    name: 'ELONXCAT',
    description: 'ELONXCAT',
    url: 'https://elonxcat.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const chains = [bsc];
const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    storage: createStorage({
        storage: cookieStorage,
    }),
    enableWalletConnect: true,
    enableInjected: true,
    enableEIP6963: true,
    enableCoinbase: true,
});

createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true,
    enableOnramp: true,
    themeVariables: {
        '--w3m-accent': '#AF0909',
    },
});

export function Wagmi({ children }) {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </WagmiProvider>
    );
}
