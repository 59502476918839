import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const BarChart = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // Data for the chart
    const data = {
      labels: [
        "Nov 2022", "Dec 2022", "Jan 2023", "Feb 2023", "Mar 2023", "Apr 2023", "May 2023", "Jun 2023", "Jul 2023",
        "Aug 2023", "Sep 2023", "Oct 2023", "Nov 2023", "Dec 2023", "Jan 2024", "Feb 2024", "Mar 2024", "Apr 2024",
        "May 2024", "Jun 2024", "Jul 2024", "Aug 2024", "Sep 2024", "Oct 2024"
      ],
      datasets: [
        {
          label: "Data",
          data: [
            20000000, 21000000, 22000000, 23000000, 24000000, 25000000, 25000000, 25000000, 25000000,
            25000000, 25000000, 25000000, 25000000, 25000000, 25000000, 25000000, 25000000, 25000000,
            25000000, 25000000, 25000000, 25000000, 25000000, 25000000
          ],
          backgroundColor: "#17345e"
        }
      ]
    };

    // Chart options
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.raw.toLocaleString()}`
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: "#ffffff",
            maxRotation: 90,
            minRotation: 90
          }
        },
        y: {
          ticks: {
            color: "#ffffff",
            callback: (value) => `${value / 1000000}M`
          },
          grid: {
            color: "#484d51f5",
          },
          beginAtZero: true
        }
      }
    };

    // Initialize Chart
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy(); // Destroy existing chart instance to prevent duplicates
    }
    chartInstanceRef.current = new Chart(ctx, {
      type: "bar",
      data,
      options
    });

    // Clean up on component unmount
    return () => {
      chartInstanceRef.current.destroy();
    };
  }, []);

  return <canvas ref={chartRef} style={{ width: '100%' }}/>;
};

export default BarChart;
