import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from '../context/TranslationContext';

const LanguageSelector = ({ isMobile = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { currentLanguage, setLanguage, languages, translate } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLanguageSelect = async (language) => {
    try {
      await setLanguage(language.code);
      setIsOpen(false);
    } catch (error) {
      console.error('Failed to change language:', error);
    }
  };

  const containerClass = `language ${isOpen ? 'active' : ''} ${
    isMobile ? 'mobile-menu-language' : ''
  }`;

  return (
    <div className={containerClass} ref={dropdownRef}>
      <div 
        className="language-btn"
        onClick={() => setIsOpen(!isOpen)}
        role="button"
        tabIndex={0}
      >
        <img
          src={currentLanguage.flag} 
          alt={`${currentLanguage.name} flag`} 
          className="language-flag" 
        />
        <span className="language-text">{currentLanguage.name}</span>
      </div>

      {isOpen && (
        <ul className="language-list">
          {languages.map((language) => (
            <button
              key={language.code}
              type="button"
              className="language-item"
              onClick={() => handleLanguageSelect(language)}
            >
              <img 
                src={language.flag} 
                alt={`${language.name} flag`} 
                className="language-flag" 
              />
              <span>{language.name}</span>
            </button>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;