export default {
  "ABOUT": "introdução",
  "TOKENOMICS": "Tokenomia",
  "STAKING": "apostar",
  "HOW TO BUY": "Como comprar",
  "ROADMAP": "roteiro",
  "FAQ": "Perguntas frequentes",
  "BUY NOW": "comprar agora",
  "ELONXCAT is the real": "ELONXCAT é real",
  "ELONMUSK": "Elon Musk",
  "SpaceX and X": "SpaceX e",
  "meme project": "projeto meme",
  "Advanced Web3 Ecosystem": "Ecossistema Web3 Avançado",
  "Potential of project": "Potencial do Projeto",
  "Market cap Target": "capitalização de mercado alvo",
  "Investors": "investidores",
  "WHITE PAPER": "livro branco",
  "AUDIT": "obrigado",
  "Presale will start soon. Join the Presale": "A pré-venda começa em breve. Participe na pré-venda",
  "TOKEN DISTRIBUTION DATE": "Data de distribuição do token",
  "Raised": "Valor arrecadado",
  "ELONXCAT sold": "Volume de vendas ELONXCAT",
  "BUY": "comprar",
  "MULTI BRIDGE UTILITY": "Utilitário de ponte múltipla",
  "Total Supply": "oferta total",
  "ELONXCAT ACHIEVEMENTS": "Desempenho do ELONXCAT",
  "Ecosystem Percentage": "proporção do ecossistema",
  "ELONXCAT QUEST GAME": "Jogo de missões ELONXCAT",
  "ELONXCAT GAME PORTAL SOON": "Portal de jogos ELONXCAT em breve",
  "NFT MARKET PLACE": "Mercado NFT",
  "Unique collections": "coleção única",
  "Launch at the same time as the project": "Lançado simultaneamente com o projeto",
  "NFT Box and Airdrop": "Caixa NFT e lançamento aéreo",
  "DISCOVER NFT": "Explorar NFTs",
  "AI-Powerde Content": "Conteúdo baseado em IA",
  "AI Image Generator": "Gerador de imagens de IA",
  "AI Chat Bot": "Bot de chat com IA",
  "AI Code": "Código de IA",
  "COIN SWAP": "Troca de moedas",
  "ELONXCAT ALLOCAT STASTISTICS": "Estatísticas de alocação ELONXCAT",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "Serviço de staking ELONXCAT em breve",
  "WELCOME TO ELONXCAT STAKING": "Bem-vindo ao Staking ELONXCAT",
  "WITHDRAW STAKED TOKENS": "Retirar tokens apostados",
  "STAKED BALANCE": "Equilíbrio de Estacamento",
  "YOUR STAKEABLE": "Saldo disponível para apostar",
  "TOTAL STAKED": "aposta total",
  "ESTIMATED REWARDS": "recompensa esperada",
  "Rewards rate is dynamic": "A proporção de recompensa é dinâmica",
  "Monthly": "mensal",
  "Daily": "olhar",
  "CURRENT REWARDS": "recompensa atual",
  "TOTAL REWARDS": "recompensa total",
  "CLAIM REWARDS": "pedido de indemnização",
  "TOTAL SUPPLY": "oferta total",
  "OUR PARTNERS and BACKERS": "Os nossos parceiros e patrocinadores",
  "ELONXCAT TEAM": "Equipa ELONXCAT",
  "Founder": "fundador",
  "Creative Director": "diretor criativo",
  "Community manger": "Gestor de comunidade",
  "CONNECT YOUR WALLET": "Conexão de carteira",
  "ENTER PRESALE": "Participação na pré-venda",
  "CLAIM ELONXCAT": "Faturação ELONXCAT",
  "ROAD MAP": "roteiro",
  "DEVELOPMENT": "desenvolvimento",
  "MEME DOMINATION": "Doação de memes",
  "FREQUENTLY ASKED QUESTIONS": "Perguntas frequentes",
  "What is a presale": "O que é uma pré-venda?",
  "What is ELONXCAT": "O que é o ELONXCAT?",
  "How do I participate in the presale": "Como participo na pré-venda?",
  "When do I receive my presale tokens": "Quando receberei os meus tokens de pré-venda?",
  "What is ELONXCAT staking": "O que é a piquetagem ELONXCAT?",
  "How do I stake my tokens": "Como faço para apostar os meus tokens?",
  "I staked my tokens during presale": "Apostei tokens durante a pré-venda, quando posso reivindicá-los?",
  "STAY CONNECTED": "fique ligado",
  "PRIVACY POLICY": "política de Privacidade",
  "HOW TO BUY": "Como comprar",
  "TERMS and CONDITIONS": "Termos de Utilização",
  "PRESALE ALLOCATION": "Alocação de pré-venda",
  "AIRDROP": "lançamento aéreo",
  "PROJECTFUNDS": "financiamento do projeto",
  "LIQUIDITY": "liquidez",
  "MARKETING": "marketing",
  "EXCHANGES": "intercâmbio",
  "ECOSYSTEM FUNDS": "fundos ecossistémicos",
  "EXPANSION": "expansão",

  "texts": {
    "txt_1": "A nossa própria tecnologia chamada X suporta este multichain. X suporta a funcionalidade de migração de cadeia, permitindo que os detentores de ELONXCAT se movimentem livremente pelas principais cadeias de criptomoedas.",
    "txt_2": "ELONXCAT é um token meme construído numa rede multichain que abrange a cadeia BNB, Ethereum, Polygon, Solana e Tron.",
    "txt_3": "A ELONXCAT esforça-se para unir a comunidade de memes criptomoedas e tornar-se pioneira em utilitários multi-cadeia.",
    "txt_4": "O nosso objetivo é fazer com que o nosso nome brilhe em cada DEX, em cada CEX e em todos os outros lugares.",
    "txt_5": "Utilizando",
    "txt_6": "X continuará a expandir estas multicadeias no futuro.",
  },
  "PRESALE TIERS": "fase de pré-venda",
  "tiers": {
    "tier_txt_1": "O preço inicial de pré-venda é de 0,0000009 USD por assento, que mudará para Tier2 após 20 dias.",
    "tier_txt_2": "O preço deste nível é definido em 0,000001 USD a partir da pré-venda, que mudará para Tier3 após 20 dias.",
    "tier_txt_3": "O preço deste nível é definido em 0,000005 USD a partir da pré-venda, que mudará para o preço final após 20 dias.",
    "tier_txt_4": "O preço de pré-venda deste nível é de 0,000009 USD, que terminará com o fim da pré-venda ELONXCAT a este preço.",
    "tier_txt_5": "O ELONXCAT pretende ser o melhor DEX no BSC com características como token swaps, pools de liquidez e produção agrícola.",
    "tier_txt_6": "ELONXCAT Quest combina jogos e criptomoedas, dando-lhe a oportunidade de ganhar tokens Lope através de missões e puzzles no mundo blockchain.",
    "tier_txt_7": "O projeto ELONXCAT NFT combina arte digital com memecoins ELONXCAT para fornecer artigos de coleção exclusivos e ricos em utilidades para aumentar o envolvimento da comunidade.",
    "tier_txt_8": "O ELONXCAT combina finanças e criatividade numa plataforma fácil de usar, integrando IA para chat, dicas de negociação, criação de memes e muito mais.",
    "tier_txt_9": "ELONXCAT Quest é um jogo blockchain dentro do ecossistema ELONXCAT Coin, combinando jogos e criptomoedas num mundo digital inspirado na mascote gato. Com missões, puzzles e desafios PVP/PVE, os jogadores podem ganhar tokens Lope através de aventura, estratégia e interação social. O jogo tem como objetivo apresentar o blockchain aos utilizadores e construir uma comunidade através de recursos multijogador.",
    "tier_txt_10": "O ELONXCAT Coin NFT Marketplace possui uma coleção única com características especiais e únicas.",
    "tier_txt_11": "Os ELONXCAT Coin NFTs serão listados juntamente com o projeto em todos os mercados globais, bem como no próprio mercado do projeto.",
    "tier_txt_12": "O mercado inclui um grande programa de lançamento aéreo que será ativado durante a caixa NFT e a pré-venda do token.",
    "tier_txt_13": "Crie blogs otimizados para SEO, e-mails de vendas e muito mais... Poupe tempo e obtenha excelentes resultados. Finalmente, existe uma ferramenta de autoria verdadeiramente utilizável.",
    "tier_txt_14": "Visualize o que sonha. Crie imagens com texto. Crie instantaneamente imagens impressionantes para a sua inovação artística com o nosso gerador de imagens de IA.",
    "tier_txt_15": "Basta selecionar o seu assistente e conversar com um chatbot amigável para gerar ideias, melhorar o seu conteúdo e, por vezes, até rir.",
    "tier_txt_16": "Pronto para escrever código à velocidade da luz? Codifique facilmente com o AI Code Generator: fornece soluções instantâneas para as suas necessidades de programação.",
    "tier_txt_17": "A ELONXCAT Swap pretende ser a plataforma líder de exchange descentralizada (DEX) na Binance Smart Chain (BSC).",
    "tier_txt_18": "O ELONXCAT Swap fornece trocas de tokens contínuas, pools de liquidez e introduz características inovadoras, como a produção agrícola e o staking para aumentar a liquidez, garantir negociações justas e promover o crescimento geral da comunidade ELONXCAT.",
    "tier_txt_19": "A distribuição das recompensas do token ELONXCAT será feita ao longo de dois anos à taxa de 708,72 $ELONXCAT por bloco Ethereum e poderá ser reclamada assim que a reclamação for ativada.",
    "tier_txt_20": "Ligue a sua carteira em segundos utilizando Metamask ou Trust Wallet.",
    "tier_txt_21": "Decida quanto $ELONXCAT pretende comprar.   Pode comprar com USDT juntamente com BNB, ETH e USDT.",
    "tier_txt_22": "Assim que a pré-venda terminar, poderá reclamar os seus tokens $ELONXCAT através da mesma carteira e rede que utilizou para comprar.",
    "tier_txt_23": "O nascimento de ELONMUSK &",
    "tier_txt_24": "Construção de comunidades  Geração de tokens  Canais sociais  Nascimento",
    "tier_txt_25": "KYC e auditoria  Bomba de marketing  Começa a pré-venda",
    "tier_txt_26": "Comece a comercializar  CoinMarketCap   Inscreva-se  Inscreva-se no CoinGecko  Atualização ElonXCAT NFT  Desenvolvimento de jogos ElonXCAT  Atualização de staking",
    "tier_txt_27": "Primeira listagem DEX   DEXTools Update   ElonXCAT AI Update  Gravação automática",
    "tier_txt_28": "Marketing contínuo  Incentivos comunitários  Parcerias com outras plataformas  Número-alvo de titulares: 1 milhão+",
    "tier_txt_29": "As pré-vendas oferecem uma oportunidade única para os investidores adquirirem novos tokens ou criptomoedas antes do seu lançamento oficial. Esta fase de acesso antecipado permite-nos capitalizar o potencial do projecto desde o início.",
    "tier_txt_30": "ELONXCAT é uma moeda meme multicadeia verdadeiramente avançada e é na realidade o ELONMUSK & Ecossistema Web3 Avançado: Estacamento | DES | IA | jogos | Mercado NFT.",
    "tier_txt_31": "Pode participar na pré-venda na rede BNB. Ligue a Best Wallet ou outra carteira ao widget de pré-venda acima e reserve tokens $ELONXCAT com BNB, ETH ou USDT.",
    "tier_txt_32": "Assim que a sua compra de pré-venda estiver concluída, poderá utilizar a mesma carteira para reivindicar os seus tokens antes da primeira listagem DEX. As datas de faturação e listagem serão anunciadas nas nossas contas de redes sociais.",
    "tier_txt_33": "Staking é o ato de enviar tokens para um contrato inteligente para os bloquear durante um determinado período de tempo. Isto permite-lhe ganhar mais tokens $ELONXCAT ao longo do tempo a uma taxa dinâmica. O staking ELONXCAT estará disponível em breve na rede BNB. Para mais informações, clique em ‘Staking’ na barra de navegação.",
    "tier_txt_34": "Para apostar $ELONXCAT, visite aqui: https://ELONXCAT/en/staking O staking só é possível com $ELONXCAT comprado no BNB. Precisará de $BNB na sua carteira para processar reclamações de recompensa de desstaking e staking.",
    "tier_txt_35": "Se apostou $ELONXCAT, deverá aguardar um período de aquisição de pelo menos 14 dias antes de o reclamar. O staking só é possível com $BNB comprados no Ethereum. Precisará de $BNB na sua carteira para processar reclamações de recompensa de desstaking e staking.",
    "tier_txt_36": "Staking é o ato de enviar tokens para um contrato inteligente para os bloquear durante um determinado período de tempo. Isto permite-lhe ganhar mais tokens $ELONXCAT ao longo do tempo a uma taxa dinâmica. O staking ELONXCAT estará disponível em breve na rede BNB. Para mais informações, clique em ‘Staking’ na barra de navegação.",
    "tier_txt_37": "Para apostar $ELONXCAT, visite aqui: https://ELONXCAT/en/staking O staking só é possível com $ELONXCAT comprado no BNB. Precisará de $BNB na sua carteira para processar reclamações de recompensa de desstaking e staking.",
    "tier_txt_38": "Se apostou $ELONXCAT, deverá aguardar um período de aquisição de pelo menos 14 dias antes de o reclamar. O staking só é possível com $BNB comprados no Ethereum. Precisará de $BNB na sua carteira para processar reclamações de recompensa de desstaking e staking.",
    "tier_txt_39": "ELONXCAT OFFICE PANAMÁ: 43º andar, Oceania Business Plaza, Tower 2000, Rua Punta Colon, Urbanisación Punta  Pacifica, Cidade do Panamá, Panamá",
    "tier_txt_40": "Isenção de responsabilidade: ELONXCAT é uma moeda meme, o seu valor é baseado exclusivamente no capital cultural universal dos memes ELONXCAT.   O valor de uma criptomoeda pode aumentar ou diminuir. Invista com cuidado."
  }
}