import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './logo.svg';
import './App.css';
import ElonXCat from './components/ElonXCat.jsx';
import { TranslationProvider } from './context/TranslationContext.jsx'

function App() {
  return (
    <TranslationProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'dark'}
      />
      < ElonXCat />
    </TranslationProvider>
  );
}

export default App;
