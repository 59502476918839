export default {
  "ABOUT": "zavedení",
  "TOKENOMICS": "Tokenomika",
  "STAKING": "vytyčování",
  "HOW TO BUY": "Jak nakupovat",
  "ROADMAP": "cestovní mapa",
  "FAQ": "Často kladené otázky",
  "BUY NOW": "koupit hned",
  "ELONXCAT is the real": "ELONXCAT je skutečný",
  "ELONMUSK": "Elon Musk",
  "SpaceX and X": "SpaceX a",
  "meme project": "meme projekt",
  "Advanced Web3 Ecosystem": "Pokročilý ekosystém Web3",
  "Potential of project": "Potenciál projektu",
  "Market cap Target": "cílová tržní kapitalizace",
  "Investors": "investoři",
  "WHITE PAPER": "bílá kniha",
  "AUDIT": "Díky",
  "Presale will start soon. Join the Presale": "Předprodej začne již brzy. Zúčastněte se předprodeje",
  "TOKEN DISTRIBUTION DATE": "Datum distribuce tokenu",
  "Raised": "Navýšená částka",
  "ELONXCAT sold": "Objem prodeje ELONXCAT",
  "BUY": "nákup",
  "MULTI BRIDGE UTILITY": "Multiple Bridge Utility",
  "Total Supply": "celková nabídka",
  "ELONXCAT ACHIEVEMENTS": "Výkon ELONXCAT",
  "Ecosystem Percentage": "ekosystémový poměr",
  "ELONXCAT QUEST GAME": "Úkolová hra ELONXCAT",
  "ELONXCAT GAME PORTAL SOON": "Herní portál ELONXCAT již brzy",
  "NFT MARKET PLACE": "NFT Marketplace",
  "Unique collections": "unikátní kolekce",
  "Launch at the same time as the project": "Vydáno současně s projektem",
  "NFT Box and Airdrop": "NFT box a výsadek",
  "DISCOVER NFT": "Prozkoumejte NFT",
  "AI-Powerde Content": "Obsah založený na umělé inteligenci",
  "AI Image Generator": "AI Image Generator",
  "AI Chat Bot": "AI chatovací robot",
  "AI Code": "AI kód",
  "COIN SWAP": "Výměna mincí",
  "ELONXCAT ALLOCAT STASTISTICS": "Statistika alokace ELONXCAT",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "Služba vytyčování ELONXCAT již brzy",
  "WELCOME TO ELONXCAT STAKING": "Vítejte v ELONXCAT Staking",
  "WITHDRAW STAKED TOKENS": "Vytáhněte vsazené žetony",
  "STAKED BALANCE": "Staking Balance",
  "YOUR STAKEABLE": "Zůstatek k dispozici pro sázky",
  "TOTAL STAKED": "celkové sázky",
  "ESTIMATED REWARDS": "očekávaná odměna",
  "Rewards rate is dynamic": "Poměr odměn je dynamický",
  "Monthly": "měsíční",
  "Daily": "pohled",
  "CURRENT REWARDS": "aktuální odměna",
  "TOTAL REWARDS": "celková odměna",
  "CLAIM REWARDS": "nárok na odškodnění",
  "TOTAL SUPPLY": "celková nabídka",
  "OUR PARTNERS and BACKERS": "Naši partneři a sponzoři",
  "ELONXCAT TEAM": "Tým ELONXCAT",
  "Founder": "zakladatel",
  "Creative Director": "kreativní ředitel",
  "Community manger": "komunitní manažer",
  "CONNECT YOUR WALLET": "Připojení peněženky",
  "ENTER PRESALE": "Předprodejní účast",
  "CLAIM ELONXCAT": "Fakturace ELONXCAT",
  "ROAD MAP": "cestovní mapa",
  "DEVELOPMENT": "rozvoj",
  "MEME DOMINATION": "Dar meme",
  "FREQUENTLY ASKED QUESTIONS": "Často kladené otázky",
  "What is a presale": "Co je předprodej?",
  "What is ELONXCAT": "Co je ELONXCAT?",
  "How do I participate in the presale": "Jak se zapojím do předprodeje?",
  "When do I receive my presale tokens": "Kdy dostanu své předprodejní tokeny?",
  "What is ELONXCAT staking": "Co je sázka ELONXCAT?",
  "How do I stake my tokens": "Jak vsadím své žetony?",
  "I staked my tokens during presale": "Vsadil jsem tokeny během předprodeje, kdy je mohu reklamovat?",
  "STAY CONNECTED": "zůstat ve spojení",
  "PRIVACY POLICY": "Zásady ochrany osobních údajů",
  "TERMS and CONDITIONS": "Podmínky použití",
  "PRESALE ALLOCATION": "Předprodejní alokace",
  "AIRDROP": "výsadek",
  "PROJECTFUNDS": "financování projektu",
  "LIQUIDITY": "likvidita",
  "MARKETING": "marketing",
  "EXCHANGES": "výměna",
  "ECOSYSTEM FUNDS": "ekosystémové fondy",
  "EXPANSION": "rozšíření",

  "texts": {
    "txt_1": "Naše vlastní technologie s názvem X podporuje tento multichain. X podporuje funkci řetězové migrace, což umožňuje držitelům ELONXCAT volně se pohybovat napříč předními řetězcí kryptoměn.",
    "txt_2": "ELONXCAT je token meme postavený na víceřetězcové síti zahrnující řetězec BNB, Ethereum, Polygon, Solana a Tron.",
    "txt_3": "ELONXCAT se snaží sjednotit komunitu kryptoměnových memů a stát se průkopníkem ve víceřetězcových utilitách.",
    "txt_4": "Naším cílem je, aby naše jméno svítilo na každém DEX, každém CEXu a všude jinde.",
    "txt_5": "Využití",
    "txt_6": "X bude tyto multichainy v budoucnu dále rozšiřovat.",
  },
  "PRESALE TIERS": "fázi předprodeje",

  "tiers": {
    "tier_txt_1": "Počáteční cena předprodeje je 0,0000009 USD za sedadlo, která se změní na Tier2 po 20 dnech.",
    "tier_txt_2": "Cena této úrovně je stanovena na 0,000001 USD od předprodeje, která se změní na Tier3 po 20 dnech.",
    "tier_txt_3": "Cena této úrovně je stanovena na 0,000005 USD od předprodeje, která se změní na konečnou cenu po 20 dnech.",
    "tier_txt_4": "Cena předprodeje této úrovně je 0,000009 USD, která skončí s koncem předprodeje ELONXCAT s touto cenou.",
    "tier_txt_5": "ELONXCAT si klade za cíl být nejlepším DEX na BSC s funkcemi, jako jsou swapy tokenů, fondy likvidity a výnosové hospodaření.",
    "tier_txt_6": "ELONXCAT Quest kombinuje hraní a kryptoměnu a dává vám příležitost vydělávat tokeny Lope prostřednictvím úkolů a hádanek ve světě blockchainu.",
    "tier_txt_7": "Projekt ELONXCAT NFT kombinuje digitální umění s memecoiny ELONXCAT a poskytuje jedinečné sběratelské předměty bohaté na užitečné funkce, které posílí zapojení komunity.",
    "tier_txt_8": "ELONXCAT spojuje finance a kreativitu v uživatelsky přívětivé platformě tím, že integruje AI pro chatování, obchodní tipy, vytváření memů a další.",
    "tier_txt_9": "ELONXCAT Quest je blockchainová hra v rámci ekosystému ELONXCAT Coin, která kombinuje hraní her a kryptoměny v digitálním světě inspirovaném maskotem kočky. Díky questům, hádankám a výzvám PVP/PVE mohou hráči získávat žetony Lope prostřednictvím dobrodružství, strategie a sociální interakce. Cílem hry je představit uživatelům blockchain a vybudovat komunitu prostřednictvím funkcí pro více hráčů.",
    "tier_txt_10": "ELONXCAT Coin NFT Marketplace má jedinečnou sbírku se speciálními a jedinečnými funkcemi.",
    "tier_txt_11": "ELONXCAT Coin NFT budou kótovány spolu s projektem na všech globálních trzích i na vlastním trhu projektu.",
    "tier_txt_12": "Trh zahrnuje velký airdrop program, který bude aktivován během předprodeje NFT boxu a tokenu.",
    "tier_txt_13": "Vytvářejte blogy optimalizované pro SEO, prodejní e-maily a další... Ušetřete čas a získejte skvělé výsledky. Konečně je tu skutečně použitelný nástroj pro tvorbu.",
    "tier_txt_14": "Vizualizujte si, o čem sníte. Vytvářejte obrázky s textem. Okamžitě vytvářejte úžasné obrázky pro své umělecké inovace s naším generátorem obrázků AI.",
    "tier_txt_15": "Jednoduše vyberte svého asistenta a chatujte s přátelským chatbotem, abyste generovali nápady, vylepšovali svůj obsah a někdy se dokonce zasmáli.",
    "tier_txt_16": "Jste připraveni psát kód rychlostí blesku? Snadno kódujte pomocí AI Code Generator: Poskytuje okamžitá řešení vašich programovacích potřeb.",
    "tier_txt_17": "ELONXCAT Swap si klade za cíl být přední platformou decentralizované burzy (DEX) na Binance Smart Chain (BSC).",
    "tier_txt_18": "ELONXCAT Swap poskytuje bezproblémové swapy tokenů, poskytuje fondy likvidity a zavádí inovativní funkce, jako je výnosové hospodaření a sázky pro zvýšení likvidity, zajištění spravedlivého obchodování a podporu celkového růstu komunity ELONXCAT.",
    "tier_txt_19": "Distribuce odměn za tokeny ELONXCAT bude probíhat po dobu dvou let ve výši 708,72 $ ELONXCAT za Ethereum blok a lze je uplatnit, jakmile bude nárok aktivován.",
    "tier_txt_20": "Připojte svou peněženku během několika sekund pomocí Metamask nebo Trust Wallet.",
    "tier_txt_21": "Rozhodněte se, kolik $ ELONXCAT chcete koupit.  Můžete nakupovat s USDT spolu s BNB, ETH a USDT.",
    "tier_txt_22": "Po skončení předprodeje si můžete své tokeny $ELONXCAT nárokovat prostřednictvím stejné peněženky a řetězce, které jste použili k nákupu.",
    "tier_txt_23": "Zrození ELONMUSK &",
    "tier_txt_24": "Budování komunity Generování tokenů Sociální kanály Narození",
    "tier_txt_25": "KYC & Audit Marketing Pump Začíná předprodej",
    "tier_txt_26": "Začněte marketing CoinMarketCap  Požádejte Požádejte o CoinGecko Aktualizace ElonXCAT NFT Vývoj her ElonXCAT Aktualizace sázek",
    "tier_txt_27": "První výpis DEX  Aktualizace DEXTools  Aktualizace ElonXCAT AI Automatické vypalování",
    "tier_txt_28": "Pokračující marketing Komunitní pobídky Partnerství s jinými platformami Cílový počet držitelů: 1 milion+",
    "tier_txt_29": "Předprodeje poskytují investorům jedinečnou příležitost k nákupu nových tokenů nebo kryptoměn před jejich oficiálním spuštěním. Tato fáze včasného přístupu nám umožňuje využít potenciál projektu od samého začátku.",
    "tier_txt_30": "ELONXCAT je skutečně pokročilý multiřetězcový meme coin a ve skutečnosti je to ELONMUSK & Advanced Web3 Ecosystem: Staking | DEX | AI | hry | NFT trh.",
    "tier_txt_31": "Zapojit se můžete do předprodeje na řetězci BNB. Připojte Best Wallet nebo jinou peněženku k předprodejnímu widgetu výše a rezervujte si tokeny $ELONXCAT u BNB, ETH nebo USDT.",
    "tier_txt_32": "Jakmile je váš předprodejní nákup dokončen, můžete použít stejnou peněženku k vyzvednutí tokenů před prvním výpisem DEX. Data fakturace a výpisu budou zveřejněna na našich účtech na sociálních sítích.",
    "tier_txt_33": "Staking je akt odesílání tokenů do inteligentní smlouvy, která je uzamkne na určitou dobu. To vám umožní vydělat více tokenů $ELONXCAT v průběhu času dynamickým tempem. ELONXCAT staking bude brzy dostupný v síti BNB. Pro více informací klikněte na ‚Staking‘ v navigační liště.",
    "tier_txt_34": "Chcete-li vsadit $ELONXCAT, navštivte zde: https://ELONXCAT/en/staking Sázky jsou možné pouze s $ELONXCAT zakoupeným na BNB. Budete potřebovat $ BNB ve své peněžence, abyste mohli zpracovat žádosti o odměnu za zrušení sázek a sázky.",
    "tier_txt_35": "Pokud jste vsadili $ELONXCAT, musíte před uplatněním nároku počkat minimálně 14 dní. Sázky jsou možné pouze s $ BNB zakoupenými na Ethereu. Budete potřebovat $ BNB ve své peněžence, abyste mohli zpracovat žádosti o odměnu za zrušení sázek a sázky.",
    "tier_txt_36": "Staking je akt odesílání tokenů do inteligentní smlouvy, která je uzamkne na určitou dobu. To vám umožní vydělat více tokenů $ELONXCAT v průběhu času dynamickým tempem. ELONXCAT staking bude brzy dostupný v síti BNB. Pro více informací klikněte na ‚Staking‘ v navigační liště.",
    "tier_txt_37": "Chcete-li vsadit $ELONXCAT, navštivte zde: https://ELONXCAT/en/staking Sázky jsou možné pouze s $ELONXCAT zakoupeným na BNB. Budete potřebovat $ BNB ve své peněžence, abyste mohli zpracovat žádosti o odměnu za zrušení sázek a sázky.",
    "tier_txt_38": "Pokud jste vsadili $ELONXCAT, musíte před uplatněním nároku počkat minimálně 14 dní. Sázky jsou možné pouze s $ BNB zakoupenými na Ethereu. Budete potřebovat $ BNB ve své peněžence, abyste mohli zpracovat žádosti o odměnu za zrušení sázek a sázky.",
    "tier_txt_39": "ELONXCAT OFFICE PANAMA: 43. patro, Oceánie BusinessPlaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, Panama City, Panama",
    "tier_txt_40": "Upozornění: ELONXCAT je memová mince, její hodnota je založena výhradně na univerzálním kulturním kapitálu memů ELONXCAT. Hodnota kryptoměny může stoupat nebo klesat. Investujte opatrně."
  }
}