export default {
  "ABOUT": "giriiş",
  "TOKENOMICS": "Tokenomik",
  "STAKING": "esnetme",
  "HOW TO BUY": "Nasıl satın alınır",
  "ROADMAP": "yol haritası",
  "FAQ": "Sıkça Sorulan Sorular",
  "BUY NOW": "Şimdi satın al",
  "ELONXCAT is the real": "ELONXCAT gerçektir",
  "ELONMUSK": "Elon Musk",
  "SpaceX and X": "SpaceX ve",
  "meme project": "meme projesi",
  "Advanced Web3 Ecosystem": "Gelişmiş Web3 Ekosistemi",
  "Potential of project": "Proje Potansiyeli",
  "Market cap Target": "hedef piyasa değeri",
  "Investors": "yatırımcılar",
  "WHITE PAPER": "beyaz kitap",
  "AUDIT": "teşekkürler",
  "Presale will start soon. Join the Presale": "Ön satış yakında başlıyor. Ön satışa katılın",
  "TOKEN DISTRIBUTION DATE": "Token Dağıtım Tarihi",
  "Raised": "Toplanan tutar",
  "ELONXCAT sold": "ELONXCAT satış hacmi",
  "BUY": "satın almak",
  "MULTI BRIDGE UTILITY": "Çoklu Köprü Yardımcı Programı",
  "Total Supply": "toplam arz",
  "ELONXCAT ACHIEVEMENTS": "ELONXCAT Performansı",
  "Ecosystem Percentage": "ekosistem oranı",
  "ELONXCAT QUEST GAME": "ELONXCAT Görev Oyunu",
  "ELONXCAT GAME PORTAL SOON": "ELONXCAT oyun portalı çok yakında",
  "NFT MARKET PLACE": "NFT Pazaryeri",
  "Unique collections": "benzersiz koleksiyon",
  "Launch at the same time as the project": "Projeyle eş zamanlı olarak yayınlandı",
  "NFT Box and Airdrop": "NFT kutusu ve airdrop",
  "DISCOVER NFT": "NFT`leri keşfedin",
  "AI-Powerde Content": "Yapay zeka tabanlı içerik",
  "AI Image Generator": "Yapay Zeka Görüntü Oluşturucu",
  "AI Chat Bot": "Yapay zeka sohbet botu",
  "AI Code": "AI kodu",
  "COIN SWAP": "Para Takası",
  "ELONXCAT ALLOCAT STASTISTICS": "ELONXCAT tahsis istatistikleri",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "ELONXCAT Staking Hizmeti Yakında Geliyor",
  "WELCOME TO ELONXCAT STAKING": "ELONXCAT Staking`e hoş geldiniz",
  "WITHDRAW STAKED TOKENS": "Stacked tokenları geri çekin",
  "STAKED BALANCE": "Staking Bakiyesi",
  "YOUR STAKEABLE": "Staking için mevcut bakiye",
  "TOTAL STAKED": "toplam bahis",
  "ESTIMATED REWARDS": "beklenen ödül",
  "Rewards rate is dynamic": "Ödül oranı dinamiktir",
  "Monthly": "aylık",
  "Daily": "bakış atmak",
  "CURRENT REWARDS": "mevcut ödül",
  "TOTAL REWARDS": "toplam ödül",
  "CLAIM REWARDS": "tazminat talebi",
  "TOTAL SUPPLY": "toplam arz",
  "OUR PARTNERS and BACKERS": "Ortaklarımız ve Sponsorlarımız",
  "ELONXCAT TEAM": "ELONXCAT Ekibi",
  "Founder": "kurucu",
  "Creative Director": "yaratıcı yönetmen",
  "Community manger": "topluluk yöneticisi",
  "CONNECT YOUR WALLET": "Cüzdan bağlantısı",
  "ENTER PRESALE": "Satış öncesi katılım",
  "CLAIM ELONXCAT": "ELONXCAT Faturalandırma",
  "ROAD MAP": "yol haritası",
  "DEVELOPMENT": "gelişim",
  "MEME DOMINATION": "Meme Bağışı",
  "FREQUENTLY ASKED QUESTIONS": "Sıkça Sorulan Sorular",
  "What is a presale": "Ön satış nedir?",
  "What is ELONXCAT": "ELONXCAT nedir?",
  "How do I participate in the presale": "Ön satışa nasıl katılabilirim?",
  "When do I receive my presale tokens": "Satış öncesi jetonlarımı ne zaman alacağım?",
  "What is ELONXCAT staking": "ELONXCAT stake etme nedir?",
  "How do I stake my tokens": "Tokenlarımı nasıl stake ederim?",
  "I staked my tokens during presale": "Ön satış sırasında jetonları stake ettim, bunları ne zaman talep edebilirim?",
  "STAY CONNECTED": "bağlantıda kalın",
  "PRIVACY POLICY": "Gizlilik Politikası",
  "HOW TO BUY": "Nasıl satın alınır",
  "TERMS and CONDITIONS": "Kullanım Koşulları",
  "PRESALE ALLOCATION": "Satış öncesi tahsis",
  "AIRDROP": "havadan yardım",
  "PROJECTFUNDS": "proje finansmanı",
  "LIQUIDITY": "likidite",
  "MARKETING": "pazarlama",
  "EXCHANGES": "değişme",
  "ECOSYSTEM FUNDS": "ekosistem fonları",
  "EXPANSION": "genleşme",
  "texts": {    
    "txt_1": "X adı verilen kendi teknolojimiz bu çoklu zinciri destekliyor. X, zincir geçiş işlevini destekleyerek ELONXCAT sahiplerinin önde gelen kripto para zincirleri arasında serbestçe hareket etmesine olanak tanır.",
    "txt_2": "ELONXCAT, BNB zinciri, Ethereum, Polygon, Solana ve Tron`u kapsayan çok zincirli bir ağ üzerine inşa edilmiş bir meme tokenidir.",
    "txt_3": "ELONXCAT, kripto para birimi meme topluluğunu birleştirmeye ve çok zincirli hizmetlerde öncü olmaya çalışıyor.",
    "txt_4": "Amacımız ismimizin her DEX`te, her CEX`te ve diğer her yerde parlamasını sağlamak.",
    "txt_5": "Kullanma",
    "txt_6": "X gelecekte bu çoklu zincirleri genişletmeye devam edecek.",
  },

  "PRESALE TIERS": "ön satış aşaması",
  "tiers": {
    "tier_txt_1": "İlk ön satış fiyatı koltuk başına 0,0000009 USD'dir ve 20 gün sonra Tier2'ye yükselecektir.",
    "tier_txt_2": "Bu katmanın fiyatı ön satıştan itibaren 0,000001 USD olarak belirlenmiştir ve 20 gün sonra Tier3'e yükselecektir.",
    "tier_txt_3": "Bu katmanın fiyatı ön satıştan itibaren 0,000005 USD olarak belirlenmiştir ve 20 gün sonra son fiyata yükselecektir.",
    "tier_txt_4": "Bu katmanın ön satış fiyatı 0,000009 USD'dir ve ELONXCAT ön satışı bu fiyatla sona erecektir.",
    "tier_txt_5": "ELONXCAT, token takasları, likidite havuzları ve getiri çiftçiliği gibi özelliklerle BSC`deki en iyi DEX olmayı hedefliyor.",
    "tier_txt_6": "ELONXCAT Quest, oyun ve kripto para birimini birleştirerek size blockchain dünyasındaki görevler ve bulmacalar yoluyla Lope tokenları kazanma fırsatı veriyor.",
    "tier_txt_7": "ELONXCAT NFT projesi, topluluk katılımını artırmak için benzersiz, kullanışlılık açısından zengin koleksiyonlar sağlamak üzere dijital sanatı ELONXCAT memecoin`leriyle birleştiriyor.",
    "tier_txt_8": "ELONXCAT, sohbet, ticaret ipuçları, meme oluşturma ve daha fazlası için yapay zekayı entegre ederek finans ve yaratıcılığı kullanıcı dostu bir platformda birleştirir.",
    "tier_txt_9": "ELONXCAT Quest, ELONXCAT Coin ekosistemi içinde yer alan ve kedi maskotundan ilham alan dijital bir dünyada oyun ve kripto para birimini birleştiren bir blockchain oyunudur. Görevler, bulmacalar ve PVP/PVE mücadeleleri içeren oyuncular, macera, strateji ve sosyal etkileşim yoluyla Lope jetonları kazanabilirler. Oyun, çok oyunculu özellikler aracılığıyla kullanıcıları blockchain ile tanıştırmayı ve bir topluluk oluşturmayı amaçlıyor.",
    "tier_txt_10": "ELONXCAT Coin NFT Marketplace, özel ve benzersiz özelliklere sahip benzersiz bir koleksiyona sahiptir.",
    "tier_txt_11": "ELONXCAT Coin NFT`leri, projenin kendi pazarının yanı sıra tüm küresel pazarlarda projeyle birlikte listelenecek.",
    "tier_txt_12": "Piyasada, NFT kutusu ve token ön satışı sırasında etkinleştirilecek büyük bir airdrop programı yer alıyor.",
    "tier_txt_13": "SEO için optimize edilmiş bloglar, satış e-postaları ve daha fazlasını oluşturun... Zamandan tasarruf edin ve mükemmel sonuçlar elde edin. Son olarak, gerçekten kullanışlı bir yazma aracı var.",
    "tier_txt_14": "Hayal ettiğiniz şeyi görselleştirin. Metin içeren görüntüler oluşturun. Yapay zeka görüntü oluşturucumuzla sanatsal inovasyonunuz için anında çarpıcı görüntüler oluşturun.",
    "tier_txt_15": "Fikir üretmek, içeriğinizi geliştirmek ve hatta bazen sizi güldürmek için asistanınızı seçin ve dost canlısı bir sohbet robotuyla sohbet edin.",
    "tier_txt_16": "Işık hızında kod yazmaya hazır mısınız? AI Code Generator ile kolayca kodlayın: Programlama ihtiyaçlarınıza anında çözümler sunar.",
    "tier_txt_17": "ELONXCAT Swap, Binance Smart Chain`de (BSC) lider merkezi olmayan borsa (DEX) platformu olmayı hedefliyor.",
    "tier_txt_18": "ELONXCAT Swap, sorunsuz token takasları sağlar, likidite havuzları sağlar ve likiditeyi artırmak, adil ticareti sağlamak ve ELONXCAT topluluğunun genel büyümesini desteklemek için getiri çiftçiliği ve staking gibi yenilikçi özellikler sunar.",
    "tier_txt_19": "ELONXCAT token ödüllerinin dağıtımı, Ethereum bloğu başına 708,72 $ELONXCAT oranında iki yıl boyunca yapılacak ve talep etkinleştirildiğinde talep edilebilecek.",
    "tier_txt_20": "Metamask veya Trust Wallet`ı kullanarak cüzdanınızı saniyeler içinde bağlayın.",
    "tier_txt_21": "Ne kadar $ELONXCAT satın almak istediğinize karar verin.   BNB, ETH ve USDT`nin yanı sıra USDT ile de satın alabilirsiniz.",
    "tier_txt_22": "Ön satış sona erdiğinde, $ELONXCAT tokenlerinizi satın aldığınız cüzdan ve zincir aracılığıyla talep edebilirsiniz.",
    "tier_txt_23": "ELONMUSK`un doğuşu &",
    "tier_txt_24": "Topluluk oluşturma  Jeton oluşturma  Sosyal kanallar  Doğum",
    "tier_txt_25": "KYC ve Denetim  Pazarlama Pompası  Ön satış başlıyor",
    "tier_txt_26": "Pazarlamaya Başlayın  CoinMarketCap   Başvur  CoinGecko`ya Başvurun  ElonXCAT NFT Güncellemesi  ElonXCAT Oyun Geliştirme  Staking Güncellemesi",
    "tier_txt_27": "İlk DEX Listesi   DEXTools Güncellemesi   ElonXCAT AI Güncellemesi  Otomatik Yazma",
    "tier_txt_28": "Devam eden pazarlama  Topluluk teşvikleri  Diğer platformlarla ortaklıklar  Hedef sahip sayısı: 1 milyon+",
    "tier_txt_29": "Ön satışlar, yatırımcılara resmi lansmanlarından önce yeni tokenlar veya kripto para birimleri satın almaları için eşsiz bir fırsat sunuyor. Bu erken erişim aşaması, projenin potansiyelinden en başından itibaren faydalanmamıza olanak tanıyor.",
    "tier_txt_30": "ELONXCAT gerçekten gelişmiş çok zincirli bir meme parasıdır ve aslında ELONMUSK & Gelişmiş Web3 Ekosistemi: Staking | DEX | AI | oyunlar | NFT pazarı.",
    "tier_txt_31": "BNB zincirindeki ön satışa katılabilirsiniz. Best Wallet`ı veya başka bir cüzdanı yukarıdaki ön satış widget`ına bağlayın ve BNB, ETH veya USDT ile $ELONXCAT tokenlarını rezerve edin.",
    "tier_txt_32": "Satış öncesi satın alma işleminiz tamamlandıktan sonra, ilk DEX listelemesinden önce tokenlarınızı almak için aynı cüzdanı kullanabilirsiniz. Fatura ve listeleme tarihleri ​​sosyal medya hesaplarımızda duyurulacaktır.",
    "tier_txt_33": "Staking, belirli bir süre boyunca kilitlemek için tokenleri akıllı bir sözleşmeye gönderme eylemidir. Bu, zaman içinde dinamik bir oranda daha fazla $ELONXCAT tokeni kazanmanıza olanak tanır. ELONXCAT staking yakında BNB ağında kullanıma sunulacak. Daha fazla bilgi için gezinme çubuğundaki `Stake` seçeneğine tıklayın.",
    "tier_txt_34": "$ELONXCAT stake etmek için burayı ziyaret edin: https://ELONXCAT/en/staking Staking yalnızca BNB`den satın alınan $ELONXCAT ile mümkündür. De-stake ve staking ödül taleplerini işlemek için cüzdanınızda $BNB`ye ihtiyacınız olacak.",
    "tier_txt_35": "Eğer $ELONXCAT stake ettiyseniz, bunu talep etmeden önce en az 14 günlük bir hak kazanma süresini beklemelisiniz. Staking yalnızca Ethereum üzerinden satın alınan $BNB ile mümkündür. De-stake ve staking ödül taleplerini işlemek için cüzdanınızda $BNB`ye ihtiyacınız olacak.",
    "tier_txt_36": "Staking, belirli bir süre boyunca kilitlemek için tokenleri akıllı bir sözleşmeye gönderme eylemidir. Bu, zaman içinde dinamik bir oranda daha fazla $ELONXCAT tokeni kazanmanıza olanak tanır. ELONXCAT staking yakında BNB ağında kullanıma sunulacak. Daha fazla bilgi için gezinme çubuğundaki `Stake` seçeneğine tıklayın.",
    "tier_txt_37": "$ELONXCAT stake etmek için burayı ziyaret edin: https://ELONXCAT/en/staking Staking yalnızca BNB`den satın alınan $ELONXCAT ile mümkündür. De-stake ve staking ödül taleplerini işlemek için cüzdanınızda $BNB`ye ihtiyacınız olacak.",
    "tier_txt_38": "Eğer $ELONXCAT stake ettiyseniz, bunu talep etmeden önce en az 14 günlük bir hak kazanma süresini beklemelisiniz. Staking yalnızca Ethereum üzerinden satın alınan $BNB ile mümkündür. De-stake ve staking ödül taleplerini işlemek için cüzdanınızda $BNB`ye ihtiyacınız olacak.",
    "tier_txt_39": "ELONXCAT OFİSİ PANAMA: 43. Kat, Okyanusya İşletmesi Plaza, Tower 2000, Punta Colon Caddesi, Urbanisación Punta  Pacifica, Panama Şehri, Panama",
    "tier_txt_40": "Yasal Uyarı: ELONXCAT bir meme parasıdır ve değeri yalnızca ELONXCAT memlerinin evrensel kültürel sermayesine dayanmaktadır.  Bir kripto para biriminin değeri artabilir veya azalabilir. Dikkatli yatırım yapın."
  }
}