export default {
  "ABOUT": "invoering",
  "TOKENOMICS": "Tokenomics",
  "STAKING": "uitzetten",
  "HOW TO BUY": "Hoe te kopen",
  "ROADMAP": "routekaart",
  "FAQ": "Veelgestelde vragen",
  "BUY NOW": "koop nu",
  "ELONXCAT is the real": "ELONXCAT is echt",
  "ELONMUSK": "Elon Musk",
  "SpaceX and X": "SpaceX en",
  "meme project": "meme-project",
  "Advanced Web3 Ecosystem": "Geavanceerd Web3-ecosysteem",
  "Potential of project": "Projectpotentieel",
  "Market cap Target": "doelmarktkapitalisatie",
  "Investors": "investeerders",
  "WHITE PAPER": "wit boek",
  "AUDIT": "Bedankt",
  "Presale will start soon. Join the Presale": "De voorverkoop start binnenkort. Neem deel aan de voorverkoop",
  "TOKEN DISTRIBUTION DATE": "Datum van tokendistributie",
  "Raised": "Opgehaald bedrag",
  "ELONXCAT sold": "ELONXCAT-verkoopvolume",
  "BUY": "aankoop",
  "MULTI BRIDGE UTILITY": "Hulpprogramma voor meerdere bruggen",
  "Total Supply": "totaal aanbod",
  "ELONXCAT ACHIEVEMENTS": "ELONXCAT-prestaties",
  "Ecosystem Percentage": "ecosysteem verhouding",
  "ELONXCAT QUEST GAME": "ELONXCAT Quest-spel",
  "ELONXCAT GAME PORTAL SOON": "ELONXCAT-gamingportaal komt binnenkort",
  "NFT MARKET PLACE": "NFT-marktplaats",
  "Unique collections": "unieke collectie",
  "Launch at the same time as the project": "Gelijktijdig met het project uitgebracht",
  "NFT Box and Airdrop": "NFT-box en airdrop",
  "DISCOVER NFT": "Ontdek NFT`s",
  "AI-Powerde Content": "Op AI gebaseerde inhoud",
  "AI Image Generator": "AI-beeldgenerator",
  "AI Chat Bot": "AI-chatbot",
  "AI Code": "AI-code",
  "COIN SWAP": "Muntenwissel",
  "ELONXCAT ALLOCAT STASTISTICS": "ELONXCAT toewijzingsstatistieken",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "ELONXCAT-uitzetservice binnenkort beschikbaar",
  "WELCOME TO ELONXCAT STAKING": "Welkom bij ELONXCAT Staking",
  "WITHDRAW STAKED TOKENS": "Ingezette tokens opnemen",
  "STAKED BALANCE": "Balans uitzetten",
  "YOUR STAKEABLE": "Saldo beschikbaar voor staking",
  "TOTAL STAKED": "totale inzet",
  "ESTIMATED REWARDS": "verwachte beloning",
  "Rewards rate is dynamic": "De beloningsverhouding is dynamisch",
  "Monthly": "maandelijks",
  "Daily": "oogopslag",
  "CURRENT REWARDS": "huidige beloning",
  "TOTAL REWARDS": "totale beloning",
  "CLAIM REWARDS": "schadevergoedingsvordering",
  "TOTAL SUPPLY": "totaal aanbod",
  "OUR PARTNERS and BACKERS": "Onze partners en sponsors",
  "ELONXCAT TEAM": "ELONXCAT-team",
  "Founder": "oprichter",
  "Creative Director": "creatief directeur",
  "Community manger": "gemeenschapsbeheerder",
  "CONNECT YOUR WALLET": "Wallet-verbinding",
  "ENTER PRESALE": "Deelname voorverkoop",
  "CLAIM ELONXCAT": "ELONXCAT-facturering",
  "ROAD MAP": "routekaart",
  "DEVELOPMENT": "ontwikkeling",
  "MEME DOMINATION": "Meme-donatie",
  "FREQUENTLY ASKED QUESTIONS": "Veelgestelde vragen",
  "What is a presale": "Wat is een voorverkoop?",
  "What is ELONXCAT": "Wat is ELONXCAT?",
  "How do I participate in the presale": "Hoe neem ik deel aan de voorverkoop?",
  "When do I receive my presale tokens": "Wanneer ontvang ik mijn voorverkooptokens?",
  "What is ELONXCAT staking": "Wat is ELONXCAT-staking?",
  "How do I stake my tokens": "Hoe zet ik mijn tokens in?",
  "I staked my tokens during presale": "Ik heb tokens ingezet tijdens de voorverkoop, wanneer kan ik ze claimen?",
  "STAY CONNECTED": "blijf verbonden",
  "PRIVACY POLICY": "Privacybeleid",
  "TERMS and CONDITIONS": "Gebruiksvoorwaarden",
  "PRESALE ALLOCATION": "Toewijzing vóór verkoop",
  "AIRDROP": "luchtdruppel",
  "PROJECTFUNDS": "projectfinanciering",
  "LIQUIDITY": "liquiditeit",
  "MARKETING": "marketing",
  "EXCHANGES": "aandelenbeurs",
  "ECOSYSTEM FUNDS": "ecosysteem fondsen",
  "EXPANSION": "uitbreiding",

  "texts": {
    "txt_1": "Onze eigen technologie genaamd X ondersteunt deze multichain. X ondersteunt ketenmigratiefunctionaliteit, waardoor ELONXCAT-houders zich vrijelijk tussen toonaangevende cryptocurrency-ketens kunnen bewegen.",
    "txt_2": "ELONXCAT is een meme-token gebouwd op een multichain-netwerk dat de BNB-keten, Ethereum, Polygon, Solana en Tron omvat.",
    "txt_3": "ELONXCAT streeft ernaar de cryptocurrency-memegemeenschap te verenigen en een pionier te worden op het gebied van multi-chain nutsvoorzieningen.",
    "txt_4": "Ons doel is om onze naam op elke DEX, elke CEX en overal elders te laten schitteren.",
    "txt_5": "Gebruikmakend",
    "txt_6": "X zal deze multichains in de toekomst blijven uitbreiden.",
  },
  "PRESALE TIERS": "fase vóór de verkoop",

  "tiers": {
    "tier_txt_1": "De initiële pre-sale prijs is 0,0000009 USD per stoel, die na 20 dagen zal veranderen naar Tier2.",
    "tier_txt_2": "De prijs van dit niveau is vastgesteld op 0,000001 USD vanaf de pre-sale, die na 20 dagen zal veranderen naar Tier3.",
    "tier_txt_3": "De prijs van dit niveau is vastgesteld op 0,000005 USD vanaf de pre-sale, die na 20 dagen zal veranderen naar de eindprijs.",
    "tier_txt_4": "De pre-sale prijs van dit niveau is 0,000009 USD, die zal eindigen met het einde van de ELONXCAT pre-sale tegen deze prijs.",
    "tier_txt_5": "ELONXCAT streeft ernaar de beste DEX op BSC te zijn met functies zoals token swaps, liquiditeitspools en yield farming.",
    "tier_txt_6": "ELONXCAT Quest combineert gaming en cryptocurrency, waardoor je de mogelijkheid krijgt om Lope-tokens te verdienen via speurtochten en puzzels in de blockchain-wereld.",
    "tier_txt_7": "Het ELONXCAT NFT-project combineert digitale kunst met ELONXCAT-memecoins om unieke, nuttige verzamelobjecten te bieden om de betrokkenheid van de gemeenschap te vergroten.",
    "tier_txt_8": "ELONXCAT combineert financiën en creativiteit in een gebruiksvriendelijk platform door AI te integreren voor chatten, handelstips, het maken van meme`s en meer.",
    "tier_txt_9": "ELONXCAT Quest is een blockchain-spel binnen het ELONXCAT Coin-ecosysteem, dat gaming en cryptocurrency combineert in een digitale wereld geïnspireerd door de kattenmascotte. Met speurtochten, puzzels en PVP/PVE-uitdagingen kunnen spelers Lope-tokens verdienen door middel van avontuur, strategie en sociale interactie. Het spel is bedoeld om gebruikers kennis te laten maken met blockchain en een community op te bouwen via multiplayer-functies.",
    "tier_txt_10": "ELONXCAT Coin NFT Marketplace heeft een unieke collectie met bijzondere en unieke kenmerken.",
    "tier_txt_11": "ELONXCAT Coin NFT`s zullen samen met het project op alle mondiale markten worden genoteerd, evenals op de eigen markt van het project.",
    "tier_txt_12": "De markt omvat een groot airdrop-programma dat wordt geactiveerd tijdens de NFT-box en token-voorverkoop.",
    "tier_txt_13": "Maak SEO-geoptimaliseerde blogs, verkoop-e-mails en meer... Bespaar tijd en behaal geweldige resultaten. Eindelijk is er een echt bruikbare auteurstool.",
    "tier_txt_14": "Visualiseer waar je van droomt. Maak afbeeldingen met tekst. Creëer direct verbluffende afbeeldingen voor uw artistieke innovatie met onze AI-beeldgenerator.",
    "tier_txt_15": "Selecteer eenvoudig uw assistent en chat met een vriendelijke chatbot om ideeën te genereren, uw inhoud te verbeteren en soms zelfs voor een lach te zorgen.",
    "tier_txt_16": "Klaar om razendsnel code te schrijven? Codeer eenvoudig met AI Code Generator: biedt directe oplossingen voor uw programmeerbehoeften.",
    "tier_txt_17": "ELONXCAT Swap wil het leidende gedecentraliseerde uitwisselingsplatform (DEX) op Binance Smart Chain (BSC) zijn.",
    "tier_txt_18": "ELONXCAT Swap biedt naadloze tokenswaps, biedt liquiditeitspools en introduceert innovatieve functies zoals yield farming en staking om de liquiditeit te verbeteren, eerlijke handel te garanderen en de algehele groei van de ELONXCAT-gemeenschap te bevorderen.",
    "tier_txt_19": "De distributie van ELONXCAT-tokenbeloningen zal over een periode van twee jaar plaatsvinden tegen een tarief van 708,72 $ ELONXCAT per Ethereum-blok, en kan worden geclaimd zodra de claim is geactiveerd.",
    "tier_txt_20": "Verbind uw portemonnee binnen enkele seconden met Metamask of Trust Wallet.",
    "tier_txt_21": "Bepaal hoeveel $ELONXCAT u wilt kopen.  U kunt kopen met USDT, samen met BNB, ETH en USDT.",
    "tier_txt_22": "Zodra de voorverkoop is afgelopen, kunt u uw $ELONXCAT-tokens claimen via dezelfde portemonnee en keten die u gebruikte om te kopen.",
    "tier_txt_23": "De geboorte van ELONMUSK &",
    "tier_txt_24": "Gemeenschapsopbouw Token genereren Sociale kanalen Geboorte",
    "tier_txt_25": "KYC & Audit Marketing Pump De voorverkoop begint",
    "tier_txt_26": "Start met marketing CoinMarketCap  Aanmelden Meld u aan voor CoinGecko ElonXCAT NFT-update ElonXCAT-gameontwikkeling Staking-update",
    "tier_txt_27": "Eerste DEX-vermelding  DEXTools-update  ElonXCAT AI-update  Automatisch branden",
    "tier_txt_28": "Doorlopende marketing Communautaire incentives Partnerschappen met andere platforms Doelaantal houders: 1 miljoen+",
    "tier_txt_29": "Pre-sales bieden investeerders een unieke kans om nieuwe tokens of cryptocurrencies te kopen vóór hun officiële lancering. Deze vroege toegangsfase stelt ons in staat om vanaf het allereerste begin het potentieel van het project te benutten.",
    "tier_txt_30": "ELONXCAT is een echt geavanceerde meme-munt met meerdere ketens en is eigenlijk de ELONMUSK & Geavanceerd Web3-ecosysteem: uitzetten | DEX | AI | spellen | NFT-markt.",
    "tier_txt_31": "U kunt deelnemen aan de voorverkoop op de BNB-keten. Verbind Best Wallet of een andere portemonnee met de bovenstaande voorverkoopwidget en reserveer $ELONXCAT-tokens met BNB, ETH of USDT.",
    "tier_txt_32": "Zodra uw voorverkoopaankoop is voltooid, kunt u dezelfde portemonnee gebruiken om uw tokens te claimen voorafgaand aan de eerste DEX-vermelding. Facturerings- en aanbiedingsdata worden aangekondigd op onze sociale media-accounts.",
    "tier_txt_33": "Uitzetten is het sturen van tokens naar een slim contract om ze voor een bepaalde periode te vergrendelen. Hierdoor kunt u in de loop van de tijd meer $ELONXCAT-tokens verdienen tegen een dynamisch tarief. ELONXCAT staking zal binnenkort beschikbaar zijn op het BNB-netwerk. Voor meer informatie klikt u op ‘Uitzetten’ in de navigatiebalk.",
    "tier_txt_34": "Om $ELONXCAT in te zetten, ga naar: https://ELONXCAT/en/staking Uitzetten is alleen mogelijk met $ELONXCAT gekocht op BNB. U heeft $BNB in ​​uw portemonnee nodig om de-staking- en staking-beloningsclaims te verwerken.",
    "tier_txt_35": "Als u $ELONXCAT heeft ingezet, moet u een wachtperiode van minimaal 14 dagen wachten voordat u het kunt claimen. Uitzetten is alleen mogelijk met $BNB gekocht op Ethereum. U heeft $BNB in ​​uw portemonnee nodig om de-staking- en staking-beloningsclaims te verwerken.",
    "tier_txt_36": "Uitzetten is het sturen van tokens naar een slim contract om ze voor een bepaalde periode te vergrendelen. Hierdoor kunt u in de loop van de tijd meer $ELONXCAT-tokens verdienen tegen een dynamisch tarief. ELONXCAT staking zal binnenkort beschikbaar zijn op het BNB-netwerk. Voor meer informatie klikt u op ‘Uitzetten’ in de navigatiebalk.",
    "tier_txt_37": "Om $ELONXCAT in te zetten, ga naar: https://ELONXCAT/en/staking Uitzetten is alleen mogelijk met $ELONXCAT gekocht op BNB. U heeft $BNB in ​​uw portemonnee nodig om de-staking- en staking-beloningsclaims te verwerken.",
    "tier_txt_38": "Als u $ELONXCAT heeft ingezet, moet u een wachtperiode van minimaal 14 dagen wachten voordat u het kunt claimen. Uitzetten is alleen mogelijk met $BNB gekocht op Ethereum. U heeft $BNB in ​​uw portemonnee nodig om de-staking- en staking-beloningsclaims te verwerken.",
    "tier_txt_39": "ELONXCAT KANTOOR PANAMA: 43e verdieping, Oceanië BusinessPlaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, Panama City, Panama",
    "tier_txt_40": "Disclaimer: ELONXCAT is een mememunt, de waarde ervan is uitsluitend gebaseerd op het universele culturele kapitaal van ELONXCAT-memes. De waarde van een cryptocurrency kan stijgen of dalen. Investeer zorgvuldig."
  }
}