export default {
  "ABOUT": "въведение",
  "TOKENOMICS": "Токеномика",
  "STAKING": "залагане",
  "HOW TO BUY": "Как да закупите",
  "ROADMAP": "пътна карта",
  "FAQ": "Често задавани въпроси",
  "BUY NOW": "купете сега",
  "ELONXCAT is the real": "ELONXCAT е истинска",
  "ELONMUSK": "Илон Мъск",
  "SpaceX and X": "SpaceX и",
  "meme project": "мем проект",
  "Advanced Web3 Ecosystem": "Разширена Web3 екосистема",
  "Potential of project": "Потенциал на проекта",
  "Market cap Target": "целева пазарна капитализация",
  "Investors": "инвеститори",
  "WHITE PAPER": "бяла книга",
  "AUDIT": "благодаря",
  "Presale will start soon. Join the Presale": "Предварителната продажба започва скоро. Участвайте в предварителната продажба",
  "TOKEN DISTRIBUTION DATE": "Дата на разпространение на токена",
  "Raised": "Събрана сума",
  "ELONXCAT sold": "Обем на продажбите на ELONXCAT",
  "BUY": "покупка",
  "MULTI BRIDGE UTILITY": "Помощна програма за множество мостове",
  "Total Supply": "общо предлагане",
  "ELONXCAT ACHIEVEMENTS": "Изпълнение на ELONXCAT",
  "Ecosystem Percentage": "екосистемно съотношение",
  "ELONXCAT QUEST GAME": "ELONXCAT Quest Game",
  "ELONXCAT GAME PORTAL SOON": "Очаквайте скоро портал за игри ELONXCAT",
  "NFT MARKET PLACE": "Пазар на NFT",
  "Unique collections": "уникална колекция",
  "Launch at the same time as the project": "Излиза едновременно с проекта",
  "NFT Box and Airdrop": "NFT кутия и airdrop",
  "DISCOVER NFT": "Разгледайте NFT",
  "AI-Powerde Content": "Съдържание, базирано на AI",
  "AI Image Generator": "AI Генератор на изображения",
  "AI Chat Bot": "AI чат бот",
  "AI Code": "AI код",
  "COIN SWAP": "Размяна на монети",
  "ELONXCAT ALLOCAT STASTISTICS": "Статистика за разпределението на ELONXCAT",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "Очаквайте скоро услугата за залагане на ELONXCAT",
  "WELCOME TO ELONXCAT STAKING": "Добре дошли в ELONXCAT Staking",
  "WITHDRAW STAKED TOKENS": "Изтеглете заложени токени",
  "STAKED BALANCE": "Баланс за залагане",
  "YOUR STAKEABLE": "Наличен баланс за залагане",
  "TOTAL STAKED": "общо залагане",
  "ESTIMATED REWARDS": "очаквана награда",
  "Rewards rate is dynamic": "Съотношението на възнаграждението е динамично",
  "Monthly": "месечно",
  "Daily": "поглед",
  "CURRENT REWARDS": "текуща награда",
  "TOTAL REWARDS": "обща награда",
  "CLAIM REWARDS": "иск за обезщетение",
  "TOTAL SUPPLY": "общо предлагане",
  "OUR PARTNERS and BACKERS": "Нашите партньори и спонсори",
  "ELONXCAT TEAM": "Екипът на ELONXCAT",
  "Founder": "основател",
  "Creative Director": "творчески директор",
  "Community manger": "мениджър на общността",
  "CONNECT YOUR WALLET": "Връзка с портфейл",
  "ENTER PRESALE": "Участие в предварителна продажба",
  "CLAIM ELONXCAT": "ELONXCAT Таксуване",
  "ROAD MAP": "пътна карта",
  "DEVELOPMENT": "развитие",
  "MEME DOMINATION": "Мем дарение",
  "FREQUENTLY ASKED QUESTIONS": "Често задавани въпроси",
  "What is a presale": "Какво е предварителна продажба?",
  "What is ELONXCAT": "Какво е ELONXCAT?",
  "How do I participate in the presale": "Как да участвам в предварителната продажба?",
  "When do I receive my presale tokens": "Кога ще получа токените си за предварителна продажба?",
  "What is ELONXCAT staking": "Какво залага ELONXCAT?",
  "How do I stake my tokens": "Как да заложа моите жетони?",
  "I staked my tokens during presale": "Заложих токени по време на предварителната продажба, кога мога да ги взема?",
  "STAY CONNECTED": "останете свързани",
  "PRIVACY POLICY": "Политика за поверителност",
  "HOW TO BUY": "Как да закупите",
  "TERMS and CONDITIONS": "Условия за ползване",
  "PRESALE ALLOCATION": "Разпределение преди продажбата",
  "AIRDROP": "airdrop",
  "PROJECTFUNDS": "проектно финансиране",
  "LIQUIDITY": "ликвидност",
  "MARKETING": "маркетинг",
  "EXCHANGES": "обмен",
  "ECOSYSTEM FUNDS": "екосистемни фондове",
  "EXPANSION": "разширение",

  "texts": {
    "txt_1": "Нашата собствена технология, наречена X, поддържа тази мултиверига. X поддържа функционалност за мигриране на вериги, което позволява на притежателите на ELONXCAT да се движат свободно във водещи вериги за криптовалута.",
    "txt_2": "ELONXCAT е мем токен, изграден върху многоверижна мрежа, обхващаща BNB верига, Ethereum, Polygon, Solana и Tron.",
    "txt_3": "ELONXCAT се стреми да обедини общността на мемовете на криптовалутите и да стане пионер в многоверижните помощни програми.",
    "txt_4": "Нашата цел е името ни да блести на всеки DEX, всеки CEX и навсякъде другаде.",
    "txt_5": "Използвайки",
    "txt_6": "X ще продължи да разширява тези мултивериги в бъдеще.",
    "PRESALE TIERS": "етап на предварителна продажба",
  },

  "tiers": {
   "tier_txt_1": "Началната цена на предварителната продажба е 0,0000009 USD на седалка, която ще се промени на Tier2 след 20 дни.",
    "tier_txt_2": "Цената на това ниво е определена на 0,000001 USD от предварителната продажба, която ще се промени на Tier3 след 20 дни.",
    "tier_txt_3": "Цената на това ниво е определена на 0,000005 USD от предварителната продажба, която ще се промени на крайната цена след 20 дни.",
    "tier_txt_4": "Цената на предварителната продажба на това ниво е 0,000009 USD, която ще приключи с края на предварителната продажба ELONXCAT на тази цена.",
    "tier_txt_5": "ELONXCAT има за цел да бъде най-добрият DEX на BSC с функции като суапове на токени, пулове на ликвидност и земеделие на доходите.",
    "tier_txt_6": "ELONXCAT Quest съчетава игри и криптовалута, като ви дава възможност да печелите Lope токени чрез куестове и пъзели в света на блокчейн.",
    "tier_txt_7": "Проектът ELONXCAT NFT съчетава дигитално изкуство с мемекойни ELONXCAT, за да осигури уникални, богати на полезности колекционерски предмети за повишаване на ангажираността на общността.",
    "tier_txt_8": "ELONXCAT съчетава финанси и креативност в удобна за потребителя платформа, като интегрира AI за чат, съвети за търговия, създаване на мемове и др.",
    "tier_txt_9": "ELONXCAT Quest е блокчейн игра в екосистемата ELONXCAT Coin, съчетаваща игри и криптовалута в дигитален свят, вдъхновен от талисмана на котката. Включвайки куестове, пъзели и PVP/PVE предизвикателства, играчите могат да печелят жетони Lope чрез приключения, стратегии и социално взаимодействие. Играта има за цел да запознае потребителите с блокчейн и да изгради общност чрез мултиплейър функции.",
    "tier_txt_10": "ELONXCAT Coin NFT Marketplace има уникална колекция със специални и уникални функции.",
    "tier_txt_11": "ELONXCAT Coin NFT ще бъдат изброени заедно с проекта на всички глобални пазари, както и на собствения пазар на проекта.",
    "tier_txt_12": "Пазарът включва голяма програма за еърдроп, която ще бъде активирана по време на предварителната продажба на NFT кутия и токен.",
    "tier_txt_13": "Създавайте оптимизирани за SEO блогове, имейли за продажби и други... Спестете време и получете страхотни резултати. Най-накрая има един наистина използваем инструмент за създаване.",
    "tier_txt_14": "Визуализирайте това, за което мечтаете. Създавайте изображения с текст. Незабавно създайте зашеметяващи изображения за вашата артистична иновация с нашия AI генератор на изображения.",
    "tier_txt_15": "Просто изберете своя асистент и разговаряйте с приятелски чатбот, за да генерирате идеи, да подобрите съдържанието си и понякога дори да се посмеете.",
    "tier_txt_16": "Готови ли сте да пишете код със светкавична скорост? Кодирайте лесно с AI Code Generator: Осигурява незабавни решения за вашите нужди от програмиране.",
    "tier_txt_17": "ELONXCAT Swap има за цел да бъде водещата платформа за децентрализиран обмен (DEX) на Binance Smart Chain (BSC).",
    "tier_txt_18": "ELONXCAT Swap осигурява безпроблемни суапове на токени, предоставя пулове на ликвидност и въвежда иновативни функции като yield farming и staking за подобряване на ликвидността, осигуряване на честна търговия и насърчаване на цялостния растеж на общността ELONXCAT.",
    "tier_txt_19": "Разпределението на наградите за токени ELONXCAT ще се извършва в продължение на две години в размер на 708,72 $ELONXCAT на блок Ethereum и може да бъде изискано, след като искът бъде активиран.",
    "tier_txt_20": "Свържете портфейла си за секунди с помощта на Metamask или Trust Wallet.",
    "tier_txt_21": "Решете колко $ELONXCAT искате да закупите. Можете да купувате с USDT заедно с BNB, ETH и USDT.",
    "tier_txt_22": "След като предварителната продажба приключи, можете да изискате вашите $ELONXCAT токени чрез същия портфейл и верига, които сте използвали за покупката.",
    "tier_txt_23": "Раждането на ELONMUSK &",
    "tier_txt_24": "Изграждане на общност Генериране на токени Социални канали Раждане",
    "tier_txt_25": "KYC и одит Маркетингова помпа Предварителната продажба започва",
    "tier_txt_26": "Започнете маркетинг CoinMarketCap  Кандидатствайте Кандидатствайте за CoinGecko Актуализация на ElonXCAT NFT Разработка на игри ElonXCAT Актуализация на залагане",
    "tier_txt_27": "Първи DEX списък  Актуализация на DEXTools  Актуализация на ElonXCAT AI Автоматично записване",
    "tier_txt_28": "Текущ маркетинг Стимули на общността Партньорства с други платформи Целеви брой притежатели: 1 милион+",
    "tier_txt_29": "Предварителните продажби предоставят уникална възможност на инвеститорите да закупят нови токени или криптовалути преди официалното им пускане на пазара. Тази фаза на ранен достъп ни позволява да се възползваме от потенциала на проекта от самото начало.",
    "tier_txt_30": "ELONXCAT е наистина усъвършенствана многоверижна мем монета и всъщност е ELONMUSK & Разширена Web3 екосистема: Залагане | DEX | AI | игри | NFT пазар.",
    "tier_txt_31": "Можете да участвате в предварителната продажба във веригата на БНБ. Свържете Best Wallet или друг портфейл към джаджата за предварителна продажба по-горе и резервирайте токени $ELONXCAT с BNB, ETH или USDT.",
    "tier_txt_32": "След като вашата предварителна покупка бъде завършена, можете да използвате същия портфейл, за да изискате вашите токени преди първата регистрация на DEX. Датите за фактуриране и изброяване ще бъдат обявени в нашите акаунти в социалните медии.",
    "tier_txt_33": "Залагането е акт на изпращане на токени към интелигентен договор, за да ги заключите за определен период от време. Това ви позволява да печелите повече токени $ELONXCAT с течение на времето при динамична скорост. ELONXCAT залагането скоро ще бъде достъпно в мрежата на BNB. За повече информация щракнете върху „Залагане“ в лентата за навигация.",
    "tier_txt_34": "За да заложите $ELONXCAT, посетете тук: https://ELONXCAT/en/staking Залагането е възможно само с $ELONXCAT, закупени от BNB. Ще имате нужда от $BNB в портфейла си, за да обработите искания за награда за дезалагане и залагане.",
    "tier_txt_35": "Ако сте заложили $ELONXCAT, трябва да изчакате период на придобиване от поне 14 дни, преди да ги поискате. Залагането е възможно само с $BNB, закупени на Ethereum. Ще имате нужда от $BNB в портфейла си, за да обработите искания за награда за дезалагане и залагане.",
    "tier_txt_36": "Залагането е акт на изпращане на токени към интелигентен договор, за да ги заключите за определен период от време. Това ви позволява да печелите повече токени $ELONXCAT с течение на времето при динамична скорост. ELONXCAT залагането скоро ще бъде достъпно в мрежата на BNB. За повече информация щракнете върху „Залагане“ в лентата за навигация.",
    "tier_txt_37": "За да заложите $ELONXCAT, посетете тук: https://ELONXCAT/en/staking Залагането е възможно само с $ELONXCAT, закупени от BNB. Ще имате нужда от $BNB в портфейла си, за да обработите искания за награда за дезалагане и залагане.",
    "tier_txt_38": "Ако сте заложили $ELONXCAT, трябва да изчакате период на придобиване от поне 14 дни, преди да ги поискате. Залагането е възможно само с $BNB, закупени на Ethereum. Ще имате нужда от $BNB в портфейла си, за да обработите искания за награда за дезалагане и залагане.",
    "tier_txt_39": "ОФИС ELONXCAT ПАНАМА: 43-ти етаж, Oceania BusinessPlaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, Панама Сити, Панама",
    "tier_txt_40": "Отказ от отговорност: ELONXCAT е мем монета, нейната стойност се основава единствено на универсалния културен капитал на мемовете ELONXCAT. Стойността на една криптовалута може да се покачва или намалява. Инвестирайте внимателно."
  }
}