export default {
  "ABOUT": "introduzione",
  "TOKENOMICS": "Tokenomics",
  "STAKING": "puntata",
  "HOW TO BUY": "Come acquistare",
  "ROADMAP": "tabella di marcia",
  "FAQ": "Domande frequenti",
  "BUY NOW": "acquista ora",
  "ELONXCAT is the real": "ELONXCAT è reale",
  "ELONMUSK": "Elon Musk",
  "SpaceX and X": "SpaceX e",
  "meme project": "progetto meme",
  "Advanced Web3 Ecosystem": "Ecosistema Web3 avanzato",
  "Potential of project": "Potenziale del progetto",
  "Market cap Target": "capitalizzazione di mercato target",
  "Investors": "investitori",
  "WHITE PAPER": "libro bianco",
  "AUDIT": "Grazie",
  "Presale will start soon. Join the Presale": "La prevendita inizierà presto. Partecipa alla prevendita",
  "TOKEN DISTRIBUTION DATE": "Data di distribuzione dei token",
  "Raised": "Importo raccolto",
  "ELONXCAT sold": "Volume delle vendite di ELONXCAT",
  "BUY": "acquistare",
  "MULTI BRIDGE UTILITY": "Utilità Bridge multipli",
  "Total Supply": "fornitura totale",
  "ELONXCAT ACHIEVEMENTS": "Prestazioni ELONXCAT",
  "Ecosystem Percentage": "rapporto dell’ecosistema",
  "ELONXCAT GAME PORTAL SOON": "Il portale di gioco ELONXCAT sarà presto disponibile",
  "NFT MARKET PLACE": "Mercato NFT",
  "Unique collections": "collezione unica",
  "Launch at the same time as the project": "Rilasciato contemporaneamente al progetto",
  "NFT Box and Airdrop": "Scatola NFT e airdrop",
  "DISCOVER NFT": "Esplora gli NFT",
  "AI-Powerde Content": "Contenuti basati sull'intelligenza artificiale",
  "AI Image Generator": "Generatore di immagini AI",
  "AI Chat Bot": "Bot di chat AI",
  "AI Code": "Codice AI",
  "COIN SWAP": "Scambio di monete",
  "ELONXCAT ALLOCAT STASTISTICS": "Statistiche di allocazione ELONXCAT",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "Il servizio di staking ELONXCAT sarà disponibile a breve",
  "WELCOME TO ELONXCAT STAKING": "Benvenuti nello staking di ELONXCAT",
  "WITHDRAW STAKED TOKENS": "Ritira i token puntati",
  "STAKED BALANCE": "Saldo della puntata",
  "YOUR STAKEABLE": "Saldo disponibile per lo staking",
  "TOTAL STAKED": "puntata totale",
  "ESTIMATED REWARDS": "ricompensa attesa",
  "Rewards rate is dynamic": "Il rapporto di ricompensa è dinamico",
  "Monthly": "mensile",
  "Daily": "occhiata",
  "CURRENT REWARDS": "ricompensa attuale",
  "TOTAL REWARDS": "ricompensa totale",
  "CLAIM REWARDS": "richiesta di risarcimento",
  "TOTAL SUPPLY": "fornitura totale",
  "OUR PARTNERS and BACKERS": "I nostri partner e sponsor",
  "ELONXCAT TEAM": "Squadra ELONXCAT",
  "Founder": "fondatore",
  "Creative Director": "direttore creativo",
  "Community manger": "gestore della comunità",
  "CONNECT YOUR WALLET": "Connessione al portafoglio",
  "ENTER PRESALE": "Partecipazione alla prevendita",
  "CLAIM ELONXCAT": "Fatturazione ELONXCAT",
  "ROAD MAP": "tabella di marcia",
  "DEVELOPMENT": "sviluppo",
  "MEME DOMINATION": "Donazione di meme",
  "FREQUENTLY ASKED QUESTIONS": "Domande frequenti",
  "What is a presale": "Cos'è una prevendita?",
  "What is ELONXCAT": "Cos'è ELONXCAT?",
  "How do I participate in the presale": "Come faccio a partecipare alla prevendita?",
  "When do I receive my presale tokens": "Quando riceverò i miei token di prevendita?",
  "What is ELONXCAT staking": "Cos'è lo staking di ELONXCAT?",
  "How do I stake my tokens": "Come posso puntare i miei token?",
  "I staked my tokens during presale": "Ho messo in staking i token durante la prevendita, quando posso richiederli?",
  "STAY CONNECTED": "rimani connesso",
  "PRIVACY POLICY": "politica sulla riservatezza",
  "TERMS and CONDITIONS": "Termini di utilizzo",
  "PRESALE ALLOCATION": "Assegnazione prevendita",
  "AIRDROP": "lancio aereo",
  "PROJECTFUNDS": "finanziamento del progetto",
  "LIQUIDITY": "liquidità",
  "MARKETING": "marketing",
  "EXCHANGES": "scambio",
  "ECOSYSTEM FUNDS": "fondi ecosistemici",
  "EXPANSION": "espansione",

  "texts": {
    "txt_1": "La nostra tecnologia chiamata X supporta questa multicatena. X supporta la funzionalità di migrazione della catena, consentendo ai possessori di ELONXCAT di spostarsi liberamente tra le principali catene di criptovalute.",
    "txt_2": "ELONXCAT è un meme token costruito su una rete multichain che abbraccia la catena BNB, Ethereum, Polygon, Solana e Tron.",
    "txt_3": "ELONXCAT si sforza di unire la comunità dei meme di criptovaluta e diventare un pioniere nei servizi multi-catena.",
    "txt_4": "Il nostro obiettivo è far brillare il nostro nome su ogni DEX, ogni CEX e ovunque.",
    "txt_5": "Utilizzando",
    "txt_6": "X continuerà ad espandere queste multichain in futuro.",
  },

  "PRESALE TIERS": "fase di prevendita",

  "tiers": {
    "tier_txt_1": "Il prezzo iniziale di prevendita è di 0,0000009 USD per posto, che cambierà a Tier2 dopo 20 giorni.",
    "tier_txt_2": "Il prezzo di questo livello è fissato a 0,000001 USD dalla prevendita, che cambierà a Tier3 dopo 20 giorni.",
    "tier_txt_3": "Il prezzo di questo livello è fissato a 0,000005 USD dalla prevendita, che cambierà al prezzo finale dopo 20 giorni.",
    "tier_txt_4": "Il prezzo di prevendita di questo livello è di 0,000009 USD, che terminerà con la fine della prevendita ELONXCAT a questo prezzo.",
    "tier_txt_5": "ELONXCAT mira ad essere il miglior DEX su BSC con funzionalità come token swap, pool di liquidità e yield farming.",
    "tier_txt_6": "ELONXCAT Quest combina giochi e criptovaluta, dandoti l'opportunità di guadagnare gettoni Lope attraverso missioni ed enigmi nel mondo blockchain.",
    "tier_txt_7": "Il progetto ELONXCAT NFT combina l'arte digitale con i memecoin ELONXCAT per fornire oggetti da collezione unici e ricchi di utilità per migliorare il coinvolgimento della comunità.",
    "tier_txt_8": "ELONXCAT combina finanza e creatività in una piattaforma intuitiva integrando l'intelligenza artificiale per chat, suggerimenti di trading, creazione di meme e altro ancora.",
    "tier_txt_9": "ELONXCAT Quest è un gioco blockchain all'interno dell'ecosistema ELONXCAT Coin, che combina giochi e criptovaluta in un mondo digitale ispirato alla mascotte del gatto. Grazie a missioni, enigmi e sfide PVP/PVE, i giocatori possono guadagnare gettoni Lope attraverso l'avventura, la strategia e l'interazione sociale. Il gioco mira a introdurre gli utenti alla blockchain e costruire una comunità attraverso funzionalità multiplayer.",
    "tier_txt_10": "ELONXCAT Coin NFT Marketplace ha una collezione unica con caratteristiche speciali e uniche.",
    "tier_txt_11": "Gli NFT di ELONXCAT Coin saranno quotati insieme al progetto su tutti i mercati globali e sul mercato proprio del progetto.",
    "tier_txt_12": "Il mercato prevede un vasto programma di airdrop che verrà attivato durante la prevendita del box NFT e dei token.",
    "tier_txt_13": "Crea blog, e-mail di vendita e altro ancora ottimizzati per il SEO... Risparmia tempo e ottieni ottimi risultati. Finalmente c'è uno strumento di creazione veramente utilizzabile.",
    "tier_txt_14": "Visualizza ciò che sogni. Crea immagini con testo. Crea istantaneamente immagini straordinarie per la tua innovazione artistica con il nostro generatore di immagini AI.",
    "tier_txt_15": "Seleziona semplicemente il tuo assistente e chatta con un chatbot amichevole per generare idee, migliorare i tuoi contenuti e, a volte, anche farti una risata.",
    "tier_txt_16": "Pronto a scrivere codice alla velocità della luce? Codifica facilmente con AI Code Generator: fornisce soluzioni istantanee alle tue esigenze di programmazione.",
    "tier_txt_17": "ELONXCAT Swap mira a essere la principale piattaforma di scambio decentralizzato (DEX) su Binance Smart Chain (BSC).",
    "tier_txt_18": "ELONXCAT Swap fornisce scambi di token senza soluzione di continuità, fornisce pool di liquidità e introduce funzionalità innovative come lo yield farming e lo staking per migliorare la liquidità, garantire un commercio equo e promuovere la crescita complessiva della comunità ELONXCAT.",
    "tier_txt_19": "La distribuzione dei premi in token ELONXCAT verrà effettuata in due anni al tasso di 708,72 $ ELONXCAT per blocco Ethereum e potrà essere richiesta una volta attivata la richiesta.",
    "tier_txt_20": "Collega il tuo portafoglio in pochi secondi utilizzando Metamask o Trust Wallet.",
    "tier_txt_21": "Decidi quanto $ELONXCAT vuoi acquistare.  Puoi acquistare con USDT insieme a BNB, ETH e USDT.",
    "tier_txt_22": "Una volta terminata la prevendita, puoi richiedere i tuoi token $ELONXCAT tramite lo stesso portafoglio e catena utilizzati per l'acquisto.",
    "tier_txt_23": "La nascita di ELONMUSK &",
    "tier_txt_24": "Community building Generazione di token Canali social Nascita",
    "tier_txt_25": "KYC e audit Pompa di marketing Inizio della prevendita",
    "tier_txt_26": "Inizia a fare marketing CoinMarketCap  Richiedi Richiedi CoinGecko Aggiornamento ElonXCAT NFT Sviluppo di giochi ElonXCAT Aggiornamento staking",
    "tier_txt_27": "Primo elenco DEX  Aggiornamento DEXTools  Aggiornamento ElonXCAT AI Masterizzazione automatica",
    "tier_txt_28": "Marketing continuo Incentivi comunitari Partnership con altre piattaforme Numero target di titolari: 1 milione+",
    "tier_txt_29": "Le prevendite offrono agli investitori un'opportunità unica di acquistare nuovi token o criptovalute prima del loro lancio ufficiale. Questa fase di accesso anticipato ci consente di sfruttare il potenziale del progetto fin dall'inizio.",
    "tier_txt_30": "ELONXCAT è una moneta meme multi-catena veramente avanzata ed è in realtà ELONMUSK & Ecosistema Web3 avanzato: staking | DES | AI | giochi | mercato NFT.",
    "tier_txt_31": "Puoi partecipare alla prevendita sulla catena BNB. Collega Best Wallet o un altro portafoglio al widget di prevendita sopra e prenota token $ELONXCAT con BNB, ETH o USDT.",
    "tier_txt_32": "Una volta completato l'acquisto in prevendita, puoi utilizzare lo stesso portafoglio per richiedere i tuoi token prima della prima quotazione DEX. Le date di fatturazione e di quotazione saranno annunciate sui nostri account di social media.",
    "tier_txt_33": "Lo staking è l'atto di inviare token a uno smart contract per bloccarli per un certo periodo di tempo. Ciò ti consente di guadagnare più token $ELONXCAT nel tempo a un tasso dinamico. Lo staking ELONXCAT sarà presto disponibile sulla rete BNB. Per ulteriori informazioni, fai clic su “Staking” nella barra di navigazione.",
    "tier_txt_34": "Per effettuare lo staking di $ELONXCAT, visita qui: https://ELONXCAT/en/staking Lo staking è possibile solo con $ELONXCAT acquistato su BNB. Avrai bisogno di $ BNB nel tuo portafoglio per elaborare le richieste di ricompensa per il de-staking e lo staking.",
    "tier_txt_35": "Se hai messo in staking $ELONXCAT, devi attendere un periodo di maturazione di almeno 14 giorni prima di richiederlo. Lo staking è possibile solo con $BNB acquistati su Ethereum. Avrai bisogno di $ BNB nel tuo portafoglio per elaborare le richieste di ricompensa per il de-staking e lo staking.",
    "tier_txt_36": "Lo staking è l'atto di inviare token a uno smart contract per bloccarli per un certo periodo di tempo. Ciò ti consente di guadagnare più token $ELONXCAT nel tempo a un tasso dinamico. Lo staking ELONXCAT sarà presto disponibile sulla rete BNB. Per ulteriori informazioni, fai clic su “Staking” nella barra di navigazione.",
    "tier_txt_37": "Per effettuare lo staking di $ELONXCAT, visita qui: https://ELONXCAT/en/staking Lo staking è possibile solo con $ELONXCAT acquistato su BNB. Avrai bisogno di $ BNB nel tuo portafoglio per elaborare le richieste di ricompensa per il de-staking e lo staking.",
    "tier_txt_38": "Se hai messo in staking $ELONXCAT, devi attendere un periodo di maturazione di almeno 14 giorni prima di richiederlo. Lo staking è possibile solo con $BNB acquistati su Ethereum. Avrai bisogno di $ BNB nel tuo portafoglio per elaborare le richieste di ricompensa per il de-staking e lo staking.",
    "tier_txt_39": "UFFICIO ELONXCAT PANAMA: 43° piano, Oceania BusinessPlaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, Panama City, Panama",
    "tier_txt_40": "Dichiarazione di non responsabilità: ELONXCAT è una moneta meme, il suo valore si basa esclusivamente sul capitale culturale universale dei meme ELONXCAT. Il valore di una criptovaluta può aumentare o diminuire. Investi con attenzione."
  }
}