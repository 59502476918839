import React, { createContext, useContext, useState, useEffect } from 'react';
// translations
import arTranslations from '../languages/ar';
import enTranslations from '../languages/en';
import bgTranslations from '../languages/bg';
import cnTranslations from '../languages/cn';
import cntTranslations from '../languages/cnt';
import cztTranslations from '../languages/cz';
import nlTranslations from '../languages/nl';
import frTranslations from '../languages/fr';
import deTranslations from '../languages/de';
import elTranslations from '../languages/el';
import huTranslations from '../languages/hu';
import idTranslations from '../languages/id';
import itTranslations from '../languages/it';
import jpTranslations from '../languages/jp';
import krTranslations from '../languages/kr';
import plTranslations from '../languages/pl';
import prTranslations from '../languages/pr';
import rmTranslations from '../languages/rm';
import ruTranslations from '../languages/ru';
import slTranslations from '../languages/sl';
import esTranslations from '../languages/es';
import thTranslations from '../languages/th';
import tuTranslations from '../languages/tu';
import vuTranslations from '../languages/vu';

// flags images
import English from "../assets/images/flags/English.svg";
import Arabic from "../assets/images/flags/Arabic.svg";
import Bulgarian from "../assets/images/flags/Bulgarian.svg";
import Chinese from "../assets/images/flags/Chinese.svg";
import Czech from "../assets/images/flags/Czech.svg";
import Dutch from "../assets/images/flags/Dutch.svg";
import French from "../assets/images/flags/French.svg";
import German from "../assets/images/flags/German.svg";
import Greek from "../assets/images/flags/Greek.svg";
import Hungarian from "../assets/images/flags/Hungarian.svg";
import Indonesian from "../assets/images/flags/Indonesian.svg";
import Italian from "../assets/images/flags/Italian.svg";
import Japanese from "../assets/images/flags/Japanese.svg";
import Korean from "../assets/images/flags/Korean.svg";
import Polish from "../assets/images/flags/Polish.svg";
import Portuguese from "../assets/images/flags/Portuguese.svg";
import Romanian from "../assets/images/flags/Romanian.svg";
import Russian from "../assets/images/flags/Russian.svg";
import Slovak from "../assets/images/flags/Slovak.svg";
import Spanish from "../assets/images/flags/Spanish.svg";
import Thai from "../assets/images/flags/Thai.svg";
import Turkish from "../assets/images/flags/Turkish.svg";
import Vietnamese from "../assets/images/flags/Vietnamese.svg";

const languages = [
  { code: 'en', name: 'English', flag: English },
  { code: 'ar', name: 'Arabic', flag: Arabic },
  { code: 'bg', name: 'Bulgarian', flag: Bulgarian },
  { code: 'cn', name: 'Chinese (simplified)', flag: Chinese },
  { code: 'cnt', name: 'Chinese (traditional)', flag: Chinese },
  { code: 'cz', name: 'Czech', flag: Czech },
  { code: 'nl', name: 'Dutch', flag: Dutch },
  { code: 'fr', name: 'French', flag: French },
  { code: 'de', name: 'German', flag: German },
  { code: 'el', name: 'Greek', flag: Greek },
  { code: 'hu', name: 'Hungarian', flag: Hungarian },
  { code: 'id', name: 'Indonesian', flag: Indonesian },
  { code: 'it', name: 'Italian', flag: Italian },
  { code: 'jp', name: 'Japanese', flag: Japanese },
  { code: 'kr', name: 'Korean', flag: Korean },
  { code: 'pl', name: 'Polish', flag: Polish },
  { code: 'pr', name: 'Portuguese', flag: Portuguese },
  { code: 'rm', name: 'Romanian', flag: Romanian },
  { code: 'ru', name: 'Russian', flag: Russian },
  { code: 'sl', name: 'Slovak', flag: Slovak },
  { code: 'es', name: 'Spanish', flag: Spanish },
  { code: 'th', name: 'Thai', flag: Thai },
  { code: 'tu', name: 'Turkish', flag: Turkish },
  { code: 'vu', name: 'Vietnamese', flag: Vietnamese },
];

const translationsMap = {
  ar: arTranslations,
  en: enTranslations,
  bg: bgTranslations,
  cn: cnTranslations,
  cnt: cntTranslations,
  cz: cztTranslations,
  nl: nlTranslations,
  fr: frTranslations,
  de: deTranslations,
  el: elTranslations,
  hu: huTranslations,
  id: idTranslations,
  it: itTranslations,
  jp: jpTranslations,
  kr: krTranslations,
  pl: plTranslations,
  pr: prTranslations,
  rm: rmTranslations,
  ru: ruTranslations,
  sl: slTranslations,
  es: esTranslations,
  th: thTranslations,
  tu: tuTranslations,
  vu: vuTranslations,
};

const TranslationContext = createContext();
const RTL_LANGUAGES = ['ar'];

export function TranslationProvider({ children }) {
  const [currentLanguage, setCurrentLanguage] = useState(
    languages.find(lang => lang.code === 'en')
  );
  const [translations, setTranslations] = useState({});

  const setLanguage = async (code) => {
    const language = languages.find(lang => lang.code === code);
    if (language) {
      setCurrentLanguage(language);
      setTranslations(translationsMap[code] || {});
      localStorage.setItem('selectedLanguage', code);
      
      // Update HTML dir and lang attributes
      //document.documentElement.dir = RTL_LANGUAGES.includes(code) ? 'rtl' : 'ltr';
      document.documentElement.lang = code;
      
      return true;
    }
    return false;
  };

  const translate = (key) => {
    // Handle nested keys like 'tiers.tier_txt_1'
    const keys = key.split('.');
    let translation = translations;
    
    for (const k of keys) {
      translation = translation?.[k];
    }
    
    if (!translation) {
      // Fallback to key if translation not found
      return key.split('.').pop().replace(/_/g, ' ');
    }
    
    return translation;
  };

  // useEffect(() => {
  //   // Add RTL handling
  //   const isRTL = RTL_LANGUAGES.includes(currentLanguage.code);
  //   document.documentElement.dir = isRTL ? 'rtl' : 'ltr';
  //   document.documentElement.lang = currentLanguage.code;
  // }, [currentLanguage]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    setLanguage(savedLanguage);
  }, []);

  return (
    <TranslationContext.Provider value={{ 
      currentLanguage, 
      setLanguage, 
      translate, 
      languages 
    }}>
      {children}
    </TranslationContext.Provider>
  );
}

export function useTranslation() {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
}