import React from 'react';

import { Wagmi } from './Wagmi';
import ContextAPI from './utils/ContextAPI';

const Wrapper = ({ children }) => {
    return (
        <>
            <Wagmi>
                <ContextAPI>
                    {children}
                </ContextAPI>
            </Wagmi>
        </>
    );
};

export default Wrapper;
